<template>
  <div class="label">
    {{lbl}}
  </div>
  <div class="legend">
    <div>Клик по складу меняет права на него.</div><br />
    <div><i class="fa fa-eye" aria-hidden="true"></i> - наблюдать за складом</div>
    <div><i class="fa fa-gamepad" aria-hidden="true"></i> - управлять складом</div>
    <div><i class="fa fa-eye-slash " aria-hidden="true"></i> - не управлять складом</div>
  </div>
  <div class="level-item" :class="`level-${el.level}`" v-for="(el, ind) in list" :key="ind">
    <div class="item" v-if="el.show" :class="{'ierarh-border': el.level > 0}">
      <div v-if="el.level > 0" class="squad-level"></div>
      <span class="el" @click="chgMode(ind)" @keydown.f9="chgMode">
        <span class="icon-left">
          <i v-if="el.chk == 0" class="fa fa-eye"
            title="наблюдать за складом" aria-hidden="true"></i>
          <i v-if="el.chk == 1" class="fa fa-gamepad"
            title="управлять складом" aria-hidden="true"></i>
          <i v-if="el.chk == 2" class="fa fa-eye-slash"
            title="не управлять складом" aria-hidden="true"></i>
        </span>
        <span>{{el.lbl}}</span>
      </span>
    </div>
  </div>

  <div class="block-link" :class="{active: blockActive}">
    <a href="">Применить действия</a>
  </div>
</template>
<script>

export default {
  name: 'IerarchSet',
  props: {
    lbl: {
      type: String,
      default: '',
    },
    err: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  emits: ['transferCats'],
  data() {
    return {
      cats: [],
      list: [],
      blockActive: 0,
      flag: 0,
      chk: false,
      select: {},
    };
  },
  mounted() {
    this.list = this.modelValue;
  },
  methods: {
    chgMode(ind) {
      this.list[ind].chk += 1;
      if (this.list[ind].chk === 3) {
        this.list[ind].chk = 0;
      }
      // console.log(this.list[ind]);
      this.$emit('update:modelValue', this.list);
    },
    changeBlockActive() {
      let i = 0;
      this.list.forEach((el) => {
        if (el.chk === 1 || el.chk === '1') {
          i += 1;
        }
      });
      if (i > 0) {
        // this.$emit('activateBlock3', 1);
        this.blockActive = 1;
      } else {
        // this.$emit('activateBlock3', 0);
        this.blockActive = 0;
      }
    },
  },
};
</script>
<style scoped>
.legend{
  font-size: 13px;
  margin-bottom: 8px;
}
.legend > div{
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.label.active{
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
}
.level-item{margin-bottom: 4px;}
.level-item i{font-size: 18px;}
i.fa-gamepad{color: #338f37;}
i.fa-eye{color: #345F99;}
i.fa-eye-slash{color: #8f3333;}

.label{
    color: #909090;
    display: inline-block;
    transition: top 0.3s, left 0.3s, font-size 0.3s, color 0.3s;
}
.icon-left{
  float:left; display:block;
  margin-right: 5px;
}
.fa-cog{
  color: #6e6e6e;
  cursor: pointer;
}
.fa-cog.active {
  color: #41a8e7;
}
.el {
  white-space:nowrap;
  display: flex;
}
.block-link {
  display: inline-block;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border: 1px solid #b9c0cf;
  scale: 0;
  transition: 0.3s;
}
.block-link.active {
  scale: 1;
}
.block-link a {
  color: #000;
}
.empty-squad{width: 12.5px; height:16px; display: inline-block; margin-right: 5px;}
.squad-level{
  width: 10px;
  height: 20px;
  border-left: 1px solid #000;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  position:relative;
  float:left;
  left:-1px;
}
.squad-level:before{
  content: '';
  display: block;
  width: 100%;
  border-top: 1px solid #000;
  position: absolute;
  top: 36%;
  left: 0px;
}
.ierarh-border{
  border-left: 1px solid #000;
}
.item{
  display: inline-block;
  cursor: pointer;
}
.item .fa-folder, .item .fa-folder-open{
  margin-right: 3px;
  float:left;
}
.item .fa-folder{ color: green; }
.item .fa-folder-open{ color: #b91717; }
.item .empty-squad .fa-folder{ color: gray; }
.squad{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #999;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  margin-right: 4px;
  float: left;
}
.squad + span {
  display: block;
  margin-left: 24px;
  line-height: 22px;
}
.level-1{margin-left: 5px}
.level-2{margin-left: 25px}
.level-3{margin-left: 45px}
.level-4{margin-left: 65px}
.level-5{margin-left: 85px}
.level-6{margin-left: 105px}
.level-7{margin-left: 125px}
.level-8{margin-left: 145px}
.level-9{margin-left: 165px}
.level-10{margin-left: 185px}

.level-1 .squad + span,
.level-2 .squad + span,
.level-3 .squad + span,
.level-4 .squad + span,
.level-5 .squad + span,
.level-6 .squad + span,
.level-7 .squad + span,
.level-8 .squad + span,
.level-9 .squad + span,
.level-10 .squad + span{margin-left: 39px;}
</style>
