<template>
  <transition name="fade">
    <Loader v-if="spiner" />
    <div v-else>
      <div v-for="(els, ind) in list" :key="ind" class="el">
        <div class="title"><div class="center">{{els.title}}</div></div>
        <div class="wrp-company" >
          <div v-for="(els2, ind2) in els.els" :key="ind2" class="el2">
            <div class="title2">{{els2.title}}</div>
            <div class="collect-one">
              <div v-for="(el, ind2) in els2.els" :key="ind2" class="one">
                <div class="inline first" :class="{error: el.ok == 0}">{{el.date}}</div>
                <div class="inline second">{{el.info}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

import Loader from '@/components/v2/cross/loader/Loader.vue';

export default {
  name: 'ListLabelGroup',
  components: {
    Loader,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      list: [],

      errors: [],
      spiner: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.action) return;
      axios.get(this.action)
        .then((res) => {
          this.list = res.data.list;
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.el{
  position: relative;
}
.title{
  text-align: center;
  padding-top: 1px;
  position: sticky;
  /* left: 50%; */
  top: 0px;
  z-index: 2;
}
.title .center{
  padding: 4px 10px;
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  background-color: #75757588;
  border-radius: 4px;
  color: #fff;
}
.collect-one{
  margin: 0.5rem 0 0.5rem 0;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.one{
  background-color: #fff;
  line-height: 1.5rem;
  padding: 10px 20px;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.25s, color 0.25s;
}
.inline{
  /* display: inline-block; */
  /* vertical-align: top; */
}
.one .first{
  margin-right: 10px;
  float: left;

  min-width: 3rem;
  padding: 0 6px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.45rem;
  color: #757575;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  font-weight: 300;
  font-size: 0.8rem;
  color: #fff;
  background-color: #6da46f;
  border-radius: 2px;
}
.one .second{
  padding-left: 72px;
}
</style>
