<template>
  <div class="wb-select"
    :class="{'error-input': err, 'req': req, active: modelValue}"
    v-click-outside="closeSelect">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <div class="wb-select-label"
      @click.stop="toggleList"
      @keydown.f9="toggleList">
      <div class="icon-right">
        <template v-if="showList">
          <i class="fa fa-chevron-up"></i>
        </template>
        <template v-else >
          <i class="fa fa-chevron-down"></i>
        </template>
      </div>
      <div class="val-text">{{modelValue ? `${modelValue.lbl} (${modelValue.cnt})` : def}}</div>
    </div>
    <transition name="fade">
      <div class="wb-select-list" v-if="showList">
        <div class="list--item" v-for="(option, index) in options" :key="index"
          @click="selectOption(option)"
          @keydown.f10="selectOption(option)">{{option.lbl}} ({{option.cnt}})</div>
      </div>
    </transition>
    <transition>
      <span class="error" v-if="err">
        <i class="close" @click="onClose"
          @keydown.f7="onClose">&#10006;</i>
        {{err}}
      </span>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SelectLoad',
  props: ['lbl', 'action', 'extFields', 'def', 'modelValue', 'err', 'req'],
  data() {
    return {
      showList: false,
      options: [],
      loader: false,
    };
  },
  methods: {
    toggleList() {
      this.showList = !this.showList;
      if ((this.showList && this.options.length === 0) || this.modelValue === 0) {
        this.loader = true;
        axios.get(this.action, { params: this.extFields })
          .then((res) => {
            this.options = res.data.list;
            this.loader = false;
          })
          .catch((e) => {
            this.loader = false;
            this.errors.push(e);
          });
      }
    },
    selectOption(option) {
      this.showList = false;
      this.$emit('update:modelValue', option);
      this.$emit('change');
    },
    closeSelect() {
      this.showList = false;
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
  },
};
</script>

<style scoped>
  .wb-select{
    position:relative;
    border-radius: 0 4px 4px 0;
    border: 1px solid #aaa;
    cursor: pointer;
    background: #fff;
    min-width: 210px;
  }
  .wb-select:hover{background:#ccc}
  .wb-select-label{
    padding: 10px 12px;
    white-space: nowrap;
  }
  .val-text{
    max-width: 200px;
    overflow: hidden;
  }
  .wb-select.w100 .val-text{max-width: 100%;}
  .wb-select-label:hover{background:#efefef;}
  .wb-select-list{
    position: absolute;
    left: -4px;
    top: 26px;
    margin-top: 10px;
    width: 100%;
    max-height: 250px;
    background: #fff;
    border: 1px solid #aaa;
    border-radius: 0px 0px 4px 4px;
    overflow-y: auto;
    border-left: 4px solid #aaa;
    z-index: 12
  }
  .list--item{padding:8px 12px }
  .list--item:hover{background:#ddd}

  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }

  /* W3C standard сейчас только для Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #848484 #d7d7d7;
  }
  /* для Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #d7d7d7;
    width:3px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #848484;
  /*  border-radius: 5px; */
    border: 2px solid #d7d7d7;
  }
  .icon-right{
    float: right;
    margin-left: 5px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .req{border-left: 4px solid #2567f3;}
  .error{
    float: right;
    position: absolute;
    display: block;
    top: 36px;
    left: -4px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    background: #f3c9c9;
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
    padding: 3px 11px;
    color: #b33d3d;
  }
  .error-input{
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
  }
  .error-input::-webkit-input-placeholder {color: #e57272;}
  .error-input:-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input::-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input:-ms-input-placeholder {color: #e57272;}
  .error-input::-ms-input-placeholder {color: #e57272;}
  .error-input::placeholder {color: #e57272;}
  .error .close{
    float: right;
    cursor: pointer;
    font-style: normal;
    margin-right: -11px;
    margin-top: -3px;
    display: block;
    width: 22px;
    height: 22px;
    text-align: center;
  }
  .mw180{min-width: 180px;}

  .wb-select.small-size {
    font-size: 14px;
    display: inline-block;
  }

  .wb-select.small-size.active{
    border-left: 4px solid #ca94ee;
  }
  .wb-select.small-size .label{
    display: none;
  }
  .wb-select.small-size .wb-select-label {
    padding: 2px 4px;
  }
  .wb-select.small-size .wb-select-list {
    top: 10px;
  }
  .wb-select.small-size .list--item {
    padding: 2px 4px;
  }
  .wb-select.small-size.active .wb-select-label {
    background: #f2dfff;
  }
</style>
