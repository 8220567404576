<template>
  <div v-if="false" class="btn-context-menu"
    @click="showContextMenu"
    @keydown.f7="showContextMenu">
    <i class="fa fa-bars" aria-hidden="true"></i>
  </div>
  <div class="navigation" v-if="gettersAuthData.token">
    <div class="level-one" v-for="(item, ind) in gettersAuthData.menu"
        :key="ind">
      <router-link :to="item.url" :title="item.name" >
        <span><i class="fa" :class="item.icon" aria-hidden="true"></i></span>
      </router-link>
      <div v-if="item.items" class="level-two">
        <router-link v-for="(el, ind) in item.items" :key="ind" :to="el.url" :title="el.name" >
          <span><i class="fa" :class="el.icon" aria-hidden="true"></i>{{el.name}}</span>
        </router-link>
      </div>
    </div>
    <div class="level-one">
      <div class="red-hover"
        @click="logout"
        @keydown.f7="logout">
        <span><i class="fa fa-sign-out" aria-hidden="true"></i></span>
      </div>
    </div>
  </div>
  <div class="wrapper-menu" v-if="flagNewHead">
    <div class="wrapper-left-search" v-if="gettersAuthData.token">
      <div class="search-left">
        <SuperSelectNew
          :field="gettersAuthData.stocks"
          @select="chgStock"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import store from '@/store/index';
import SuperSelectNew from '@/components/v2/serj/select/SuperSelectNew.vue';

export default {
  name: 'WBNavigation',
  emits: ['showContextMenu'],
  components: {
    SuperSelectNew,
  },
  data() {
    return {
      flag_menu: localStorage.getItem('user'),
    };
  },
  computed: {
    ...mapGetters('auth', {
      gettersAuthData: 'getAuthData',
    }),
    flagNewHead() {
      let res = false;
      if (this.$route.name === 'IfaceStockSet') {
        res = true;
      }
      return res;
    },
  },
  methods: {
    ...mapActions('auth', {
      actionLogout: 'logout',
      actionChgStock: 'chgstock',
    }),
    logout() {
      this.actionLogout();
      this.$router.push('/login');
    },
    showContextMenu() {
      this.$emit('showContextMenu');
    },
    chgStock(val) {
      store.dispatch('auth/chgStock', val);
    },
  },
};
</script>

<style scoped>
  .navigation {
    position: fixed;
    margin: 0 auto;
    top: 0px;
    left: 50%;
    margin-left: -148px;
    z-index: 3;
  }
  .navigation .level-one{
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
  .navigation .level-one > a, .navigation .level-one > .red-hover{
    display: block;
    text-decoration:none;
    padding:12px;
    transition:all 0.5s;
    border-radius: 0 0 4px 4px;
  }
  .navigation .level-one > a.router-link-active{
    line-height:24px
  }
  .navigation .level-one > a, .navigation .level-one > .red-hover{
    font-weight: bold;
    color: #2c3e50;
    background:#dedede;
    margin:0 4px;
  }
  .navigation .level-one > div.red-hover{
    margin-left:20px;
    cursor: pointer;
  }
  .navigation .level-one:hover > a{
    line-height:24px
  }
  .navigation div.red-hover:hover{
    background:#fb9292;
  }
  .navigation a.router-link-exact-active {
    color: #fff;
    background:#5981ce;
    cursor:default;
  }

  .level-two{
    display: none;
    position: absolute;
    top: 48px;
    left: 4px;
    background: #fff;
  }

  .level-one:hover .level-two{
    display: block;
  }
  .level-two > a{
    white-space: nowrap;
    background: #dedede;
    text-align: left;
    display: block;
    padding: 4px 12px;
    margin: 2px 0px;
    color: #000;
    transition: background 0.5s;
  }
  .level-two > a:hover {
    background: #bbb;
  }

  .level-two > a.router-link-exact-active:hover {
    background: #5981ce;
  }
  .level-two > a i{
    margin-right: 6px;
  }
</style>
