<template>
  <div class="wb-input-text">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <div style="display:flex">
      <div style="position:relative; width: 60px">
        <InputText class="v2"
          :lbl="hTitle"
          :def="hTitle"
          :err="err1"
          :req="true"
          :ind="1"
          @input="onInputHour"
          v-model="val1"></InputText>
      </div>
      <div class="separator">:</div>
      <div style="position:relative; width: 60px">
        <InputText class="v2"
          :lbl="mTitle"
          :def="mTitle"
          :err="err2"
          :req="true"
          :ind="2"
          @input="onInputMinute"
          v-model="val2"></InputText>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/v2/cross/form/fields/InputText.vue';

export default {
  name: 'InputTime',
  props: {
    lbl: {
      type: String,
      default: '',
    },
    def: {
      type: String,
      default: '',
    },
    err: {
      type: String,
      default: '',
    },
    req: {
      type: Boolean,
      default: false,
    },
    ind: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: String,
    },
  },
  components: {
    InputText,
  },
  emits: ['update:modelValue', 'outError'],
  data() {
    return {
      show_ext_lbl: false,
      id: null,
      val1: this.modelValue.split(':')[0],
      val2: this.modelValue.split(':')[1],

      mMin: 0,
      mMax: 59,
      hMin: 0,
      hMax: 23,
      hTitle: 'часы',
      mTitle: 'минуты',
    };
  },
  mounted() {
    this.id = this.$.uid;
  },
  methods: {
    onInputHour(event) {
      if (event.target.value.length !== 0) {
        this.$emit('outError', this.ind);
      }
      if (event.target.value > this.hMax) {
        this.val1 = this.hMax;
      }
      if (event.target.value < this.hMin) {
        this.val1 = this.hMin;
      }
      this.$emit('update:modelValue', `${this.val1}:${this.val2}`);
    },
    onInputMinute(event) {
      if (event.target.value.length !== 0) {
        this.$emit('outError', this.ind);
      }
      if (event.target.value > this.mMax) {
        this.val2 = this.mMax;
      }
      if (event.target.value < this.mMin) {
        this.val2 = this.mMin;
      }
      this.$emit('update:modelValue', `${this.val1}:${this.val2}`);
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
  },
};
</script>

<style scoped>
  .wb-input-text{
    position: relative;
    margin-left: 0px;
  }
  input {
    display: inline-block;
    width: 100%;
    padding: 10px 12px;
    border-radius: 0 4px 4px 0px;
    border: 1px solid #aaa;
    border-left: 4px solid #aaa;
    font-size: 16px;
    line-height: 18px;
    outline: none;
    box-sizing: border-box;
  }
  input:hover{background:#efefef}
  .ext-label{
    position: absolute;
    top: -10px;
    left: 8px;
    display: block;
    border: 1px solid #aaa;
    padding: 0px 6px;
    font-size: 12px;
    background: #efefef;
  }
  .label{
    top: 0px; left: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: #909090;
  }
  .req{border-left: 4px solid #2567f3;}
  .error{
    float: right;
    position: absolute;
    display: block;
    top: 36px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    background: #f3c9c9;
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
    padding: 3px 11px 2px;
    color: #b33d3d;
  }
  .error-input{
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
  }
  .error-input::-webkit-input-placeholder {color: #e57272;}
  .error-input:-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input::-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input:-ms-input-placeholder {color: #e57272;}
  .error-input::-ms-input-placeholder {color: #e57272;}
  .error-input::placeholder {color: #e57272;}
  .error .close{
    float: right;
    cursor: pointer;
    font-style: normal;
    margin-right: -11px;
    margin-top: 0px;
    display: block;
    width: 18px;
    height: 18px;
    text-align: center;
  }
  .separator{
    font-size: 18px;
    font-weight: bold;
    margin: 0px 10px;
    line-height: 22px;
    padding-top: 8px;
  }
  .tField{
    position: absolute;
    right: 0px;
    bottom: -12px;
    font-size: 12px;
    line-height: 14px;
    background: #e7e7e7;
    padding: 2px 4px;
    border: 1px solid #aaa;
  }
</style>
