<template>
  <div class="spinner"
    :class="{'no-fon': fon === 'off'}">
    <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
  </div>
</template>

<script>
export default {
  name: 'LoaderDef',
  props: {
    fon: {
      type: String,
      default: 'on',
    },
  },
};
</script>

<style scoped>
.spinner {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
}
.small.spinner{
  display: inline-block;
  margin-top: 12px;
  height: auto;
}

.spinner i{background: #aaa; padding: 3px}
.spinner.no-fon i{background: none;}
</style>
