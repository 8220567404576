<template>
  <h1><span>Массовое изменение настроек товара</span></h1>
  <template v-if="!globalLoader">
    <div v-if="form[0]" class="field" style="float:right; margin-bottom: 10px;">
      <Checkbox v-model="form[0].missing.value"
        :text="form[0].missing.lbl"
        :err="form[0].missing.error"
        @change="changeRoute(1)" />
      <Checkbox v-model="form[0].nocontroll.value"
        :text="form[0].nocontroll.lbl"
        :err="form[0].nocontroll.error"
        @change="changeRoute(2)" />
    </div>
    <div class="center-mess" v-if="globalMess != ''">
      <div class="inner-mess">{{globalMess}}</div>
    </div>
    <div v-if="stock" class="field" style="width: 50%">
      <SelectCustom v-model="stock.value"
        class="w100"
        :def="stock.placeholder"
        :lbl="stock.lbl"
        :options="stock.options"
        :err="stock.error"
        @change="selectStock" />
    </div>
    <div v-if="stock" class="wb-r">

      <div class="wb-c">
        <div class="head">
          <div class="right">
            <div v-if="category.conf.all" class="pick"
              @click="allCats"
              @keydown.f7="allCats">
              {{category.conf.all}}</div>
            <div v-if="category.conf.empty" class="pick"
              @click="clearCats"
              @keydown.f7="clearCats" >
              {{category.conf.empty}}
            </div>
          </div>
          <span>{{category.lbl}}</span>
        </div>
        <div class="body">
          <div class="sett">
            <Checkbox v-model="category.conf.mode.value"
              :text="category.conf.mode.lbl"
              :err="category.conf.mode.error" />
          </div>
          <div class="els">
            <ListIerarchDef
              :modeload="1"
              :action="category.action"
              :stock="stock.value.val"
              mode="checkbox"
              @sel="setCategory"
              @selprod="selCatProduct"
              :key="stock.value"
            />
          </div>
        </div>
      </div>

      <div class="wb-c" :class="{close: category.conf.mode.value}">
        <div class="head">
          <div class="right" v-if="product.conf">
            <div v-if="product.conf.all" class="pick"
              @click="allProds"
              @keydown.f7="allProds" >
              {{category.conf.all}}</div>
            <div v-if="product.conf.empty" class="pick"
              @click="clearProds"
              @keydown.f7="clearProds">
              {{category.conf.empty}}
            </div>
          </div>
          {{product.lbl}}
        </div>
        <div v-if="prodLoader" class="spinner">
          <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        </div>
        <div class="body" v-else-if="product.options.length">
          <div class="badge-cnt">{{product.options.length}}</div>
          <div class="els">
            <div v-for="(el, ind) in product.options" :key="ind"
              class="el"
              @click="checkProd(ind)"
              @keydown.f7="checkProd(ind)" >
              <div class="squad">
                <span v-if="el.chk">&#10004;</span>
              </div>
              <span>{{el.lbl}}</span>
              <div v-for="(miss, ind2) in el.missing" :key="ind2"
              class="miss"
              :title="miss.hint">
                <template v-if="miss.type == 'tx'">
                  <span>{{miss.icon}}</span>
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                </template>
                <template v-if="miss.type == 'fa'">
                  <i class="fa" :class="miss.icon" aria-hidden="true"></i>
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-warn-1">
          Выберите категории из столбца слева
        </div>
      </div>

      <div class="wb-c" :class="{big: category.conf.mode.value}">
        <div class="head">
          Операции
        </div>
        <div v-if="product.options.length" class="body">
          <div class="els operations">
            <div v-if="loader" class="el--loader">
              Выполнение операции...
              <i class="fa fa-spinner fa-spin white" aria-hidden="true"></i>
              <i class="fa fa-spinner fa-spin blue" aria-hidden="true"></i>
              <i class="fa fa-spinner fa-spin red" aria-hidden="true"></i>
            </div>
            <div v-for="(el, ind) in operations" :key="ind"
            class="el" :class="{active: el.show}">
              <div class="el--head"
                @click="toggleProp(ind)"
                @keydown.f7="toggleProp(ind)">
                <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                {{el.lbl}}
              </div>
              <div v-if="el.show" class="el--body">
                <div v-for="(el2, ind2) in el.fields" :key="ind2" class="el--field">
                  <template v-if="el2.type == 'L'">
                    <SelectCustom v-model="el2.value"
                      :def="el2.placeholder"
                      :lbl="el2.lbl"
                      :options="el2.options"
                      :err="el2.error" />
                  </template>
                  <template v-if="el2.type == 'I'">
                    <input-text v-model="el2.value"
                      :def="el2.placeholder"
                      :lbl="el2.lbl"
                      :err="el2.error"
                      :req="el2.required" />
                  </template>
                  <template v-if="el2.type == 'Z'">
                    <SelectCustom v-model="el2.value"
                      class="w100"
                      :def="el2.placeholder"
                      :lbl="el2.lbl"
                      :options="el2.options"
                      :err="el2.error" />
                    <div v-if="el2.value.fields">
                      <div v-for="(el3, ind3) in el2.value.fields" :key="ind3" class="el---el3">
                        <template v-if="el3.type == 'I'">
                          <input-text v-model="el3.value"
                            :def="el3.placeholder"
                            :lbl="el3.lbl"
                            :err="el3.error"
                            :req="el3.required" />
                        </template>
                      </div>
                    </div>
                  </template>
                  <template v-if="el2.type == 'MX'">
                    <div class="el--form">
                      <div v-for="(el3, ind3) in el2.options" :key="ind3"
                      :class="{'c-2': el3.type == 'L', 'c-5': el3.type == 'I'}"
                      >
                        <template v-if="el3.type == 'L'">
                          <SelectCustom v-model="el3.value"
                            :def="el3.placeholder"
                            :lbl="el3.lbl"
                            :options="el3.options"
                            :err="el3.error" />
                        </template>
                        <template v-if="el3.type == 'I'">
                          <input-text v-model="el3.value"
                            :def="el3.placeholder"
                            :lbl="el3.lbl"
                            :err="el3.error"
                            :req="el3.required" />
                        </template>
                      </div>
                      <div class="c-8">
                        <i @click="addPack(ind, ind2)" class="fa fa-plus" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="pack-common">
                      <div v-for="(el3, ind3) in this.packCommon" :key="ind3"
                        class="pack-group">
                        <i @click="removePack(ind, ind3)"
                          class="fa fa-times" aria-hidden="true"></i>
                        <div class="pack-item c-2">{{el3[0].value.lbl}}</div>
                        <div class="pack-item c-5">{{el3[1].value}}</div>
                        <div class="pack-item c-5">{{el3[2].value}}</div>
                      </div>
                    </div>
                  </template>
                </div>
                <div v-if="el.btn.show" class="wb-btn-sh wb-btn-success"
                  @click="runOperation(ind, $event)"
                  @keydown.f7="runOperation(ind, $event)" >
                  {{el.btn.lbl}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </template>
  <div v-else class="spinner">
    <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
  </div>
</template>

<script>
import axios from 'axios';
import SelectCustom from '@/components/atoms/Select.vue';
import InputText from '@/components/atoms/InputText.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';

import ListIerarchDef from '@/components/atoms/ListIerarchDef.vue';

export default {
  name: 'IfaceProductSet',
  components: {
    SelectCustom, InputText, Checkbox, ListIerarchDef,
  },
  data() {
    return {
      form: [],
      operations: [],
      action: '',

      packCommon: [],

      globalLoader: false,
      prodLoader: false,
      globalMess: '',
      loader: false,
      errors: [],
    };
  },
  mounted() {
    this.settIface();
  },
  computed: {
    stock() {
      let stock = false;
      if (this.form[0]) {
        stock = this.form[0].stock;
      }
      return stock;
    },
    category() {
      return this.form[1];
    },
    product() {
      return this.form[2];
    },
  },
  methods: {
    settIface() {
      this.globalLoader = true;
      let url = '/scm/product-multi/sett-iface';
      let flagParam = 0;

      if (this.$route.query.stock) {
        if (flagParam === 0) {
          url += '?';
          flagParam = 1;
        } else {
          url += '&';
        }
        url += `stock=${this.$route.query.stock}`;
      }

      if (this.$route.query.mode) {
        if (flagParam === 0) {
          url += '?';
          flagParam = 1;
        } else {
          url += '&';
        }
        url += `mode=${this.$route.query.mode}`;
      }

      axios.get(url)
        .then((res) => {
          if (res.data.success === 1) {
            this.form = res.data.form;
            this.operations = res.data.operations;
            this.action = res.data.action;
            this.globalMess = '';
          } else if (res.data.success === 2) {
            this.form = res.data.form;
            this.globalMess = res.data.answer;
          }
          this.globalLoader = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.globalLoader = false;
          this.globalMess = '';
        });
    },
    /*
    selectStock() {
      this.form[2].options = [];
      axios.get('/scm/product-multi/list-categories', {
        params: {
          val: this.stock.value.val,
          val2: this.form[0].missing.value,
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.form[1].options = res.data.list;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    */
    changeRoute(mode) {
      let url = '';
      const path = '/scm/product/set';
      const query = { mode };

      if (this.form[0].missing.value || this.form[0].nocontroll.value) {
        url = { path, query };
      } else {
        url = path;
      }
      this.$router.push(url);
      setTimeout(() => this.settIface(), 300);
    },
    allCats() {
      let i = this.category.options.length;
      while (i) {
        i -= 1;
        this.category.options[i].chk = true;
      }
      this.sendProds(-1);
    },
    clearCats() {
      let i = this.category.options.length;
      while (i) {
        i -= 1;
        this.category.options[i].chk = false;
      }
      this.sendProds(-1);
    },
    allProds() {
      let i = this.product.options.length;
      while (i) {
        i -= 1;
        this.product.options[i].chk = true;
      }
    },
    clearProds() {
      let i = this.product.options.length;
      while (i) {
        i -= 1;
        this.product.options[i].chk = false;
      }
    },
    sendProds(ind) {
      this.prodLoader = true;
      if (ind !== -1) {
        if (this.category.options[ind].chk) {
          this.category.options[ind].chk = false;
        } else {
          this.category.options[ind].chk = true;
        }
      }

      axios.post('/scm/product-multi/list-products', { form: this.form })
        .then((res) => {
          if (res.data.success === 1) {
            this.form[2].options = res.data.list;
          }
          this.prodLoader = false;
        })
        .catch((e) => {
          // if (e.response.status === 403) {
          //  this.$router.push('Login');
          // }
          this.prodLoader = false;
          this.errors.push(e);
        });

      /*
      axios.get('/scm/product-multi/list-products', {
        params: {
          form: this.form,
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.form[2].options = res.data.list;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      */
    },
    checkProd(ind) {
      if (this.product.options[ind].chk) {
        this.product.options[ind].chk = false;
      } else {
        this.product.options[ind].chk = true;
      }
    },
    toggleProp(ind) {
      if (this.operations[ind].show) {
        this.operations[ind].show = false;
      } else {
        let i = this.operations.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.operations[i].show = true;
          } else {
            this.operations[i].show = false;
          }
        }
      }
    },
    runOperation(ind, event) {
      this.loader = true;

      axios.post(this.action, {
        stock: this.stock,
        cats: this.category,
        prods: this.product,
        field: this.operations[ind],
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
            if (parseInt(this.form[0].missing.value, 10) === 1) {
              this.sendProds(-1);
            }
          } else {
            this.answer(event.pageX, event.pageY, res.data.answer, 'error');
          }
          this.loader = false;
        })
        .catch((e) => {
          // if (e.response.status === 403) {
          //  this.$router.push('Login');
          // }
          this.errors.push(e);
        });
    },
    answer(x, y, text, type) {
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'error') {
        el.style.background = '#e9bebe';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.boxShadow = '0px 1px 2px #000';
      el.style.borderRadius = '4px';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${y}px`;
      el.style.left = `${x}px`;
      // el.position.top = `${x} px`;
      // el.position.left = `${y} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${y - 50}px`;
        el.style.left = `${x}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    },
    addPack(ind, ind2) {
      const el = JSON.parse(JSON.stringify(this.operations[ind].fields[ind2].options));
      this.packCommon.push(el);

      if (this.packCommon.length) {
        this.operations[ind].btn.show = true;
      } else {
        this.operations[ind].btn.show = false;
      }

      this.operations[ind].fields[0].value = this.packCommon;
    },
    removePack(ind, ind3) {
      this.packCommon.splice(ind3, 1);

      if (this.packCommon.length) {
        this.operations[ind].btn.show = true;
      } else {
        this.operations[ind].btn.show = false;
      }

      this.operations[ind].fields[0].value = this.packCommon;
    },
    setCategory(list) {
      if (this.prodLoader === false) {
        this.form.splice(3, 1);
        this.category.options = list;
        this.sendProds(-1);
      }
    },
    selCatProduct(prods) {
      if (this.prodLoader === false) {
        this.form[3] = prods;
        this.sendProds(-1);
      }
    },
  },
};
</script>

<style scoped>
.center-mess{
  height: calc(100vh - 100px);
  line-height: calc(100vh - 100px);
  text-align: center;
}
.center-mess .inner-mess{
  display: inline-block;
  line-height: 20px;
  background: #b7e3c0;
  padding: 20px 30px;
}
.miss{
  display: inline-block;
  background: #b92929;
  color: #fff;
  padding: 1px 4px;
  position: relative;
  font-size: 12px;
  margin-right: 2px;
  cursor: help;
}
.miss .fa-times-circle{
  color: #b92929;
  position: absolute;
  left: 80%;
  top: 50%;
  background: #fff;
  border-radius: 10px;
  display: none;
}
.fa.fa-plus{
  width: 40px;
  height:40px;
  font-size:36px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: color 0.4s;
}
.fa.fa-plus:hover{
  color: #1e9516;
}

.pack-common .fa-times{
  float: right;
  width: 20px;
  height: 20px;
  font-size: 23px;
  color: #db3636;
  cursor: pointer;
  margin-right: 18px;
}
.pack-common .fa-times:hover{
  color: #ef1d1d;
}

.pack-item.c-2{
  z-index: 0;
  padding: 5px 2px;
  margin: 0px;
}
.pack-item.c-5{
  padding: 5px 2px;
  margin:0px;
}
.pack-group:hover{
 background: #ddd;
}

.c-2{
  width: calc(100% / 2);
  display: inline-block;
  padding-left: 0px;
  padding-right: 2px;
  box-sizing: border-box;
  margin-bottom: 10px;
  vertical-align: top;
  position: relative;
  z-index: 2;
}
.c-4{
  width: calc(100% / 4);
  display: inline-block;
  vertical-align: top;
  padding-left: 0px;
  padding-right: 2px;
  box-sizing: border-box;
  margin-bottom: 10px;

  position: relative;
  z-index: 2;
}
.c-5{
  width: calc(100% / 5);
  display: inline-block;
  vertical-align: top;
  padding-left: 0px;
  padding-right: 2px;
  box-sizing: border-box;
  margin-bottom: 10px;

  position: relative;
  z-index: 2;
}
.c-6{
  width: calc(100% / 6);
  display: inline-block;
  vertical-align: top;
  padding-left: 0px;
  padding-right: 2px;
  box-sizing: border-box;
  margin-bottom: 10px;

  position: relative;
  z-index: 2;
}
.c-8{
  width: calc(100% / 10);
  display: inline-block;
  vertical-align: top;
  padding-left: 0px;
  padding-right: 2px;
  box-sizing: border-box;
  margin-bottom: 10px;

  position: relative;
  z-index: 2;
}
.head .pick{
  float: right;
  display: inline-block;
  margin-left:10px;
  cursor: pointer;
}
.head .pick:hover{
  background: #555;
}

.body .sett{
  margin:8px 0px;
}
.els .el {
  margin:5px 0px;
}
.els .el:hover{
  background: #ddd;
  cursor: pointer;
}
.squad{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #999;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  margin-right: 4px;
  float: left;
}
.squad + span {
    display: block;
    margin-left: 26px;
    line-height: 22px;
}
.wb-r{
  height: calc(100vh - 210px);
  box-sizing: border-box;
  padding: 0px 10px;
}
.wb-c{
  width: 33.33%;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  border-right:1px solid #ddd;
  transition: width 0.5s ease-out 0.3s;
  height: calc(100vh - 154px);
  overflow: hidden;
  transition: background 0.4s, width 0.4s;
}
.wb-c.close{
  width: 0%;
}
.wb-c.big{
  width: 66.33%;
}
.wb-c:hover{
  background: #f1f1f1;
}
.field{
  padding: 0px 10px;
  margin-bottom: 20px;
}
.head{
  background: #5981ce;
  color:#fff;
  padding: 2px 10px;
}
.head .right{
  float:right
}
.body{
  padding: 6px 10px 0px;
  height: calc(100vh - 185px);
  overflow: hidden;
  overflow-y: auto;
}
.els.operations{
  position: relative;
}
.els.operations .el{
  margin: 2px 0px;
  background: #fff;
}
.el--head{
  padding: 4px 17px;
  border:1px solid #c7c7c7;
  background: #ddd;
}
.el--head:hover{
  background: #aaa;
}
.els.operations .el.active .el--head{
  background: #c5e2fb;
  border: 1px solid #7db8eb;
  border-bottom: 0px;
}
.el--head i {
  float: right;
}
.el--body{
  border: 1px solid #7db8eb;
  border-top: none;
  padding: 6px 4px;
  cursor: default;
}
.el--field{
  margin-top: 12px;
  margin-bottom: 6px;
}
.el--loader{
  background: #fff;
  border: 1px solid #000;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;

  min-height: 100%;
  z-index: 1;
  text-align: center;
  line-height: calc(100vh - 216px);
}
.el---el3{
  margin-top:15px;
}
.white{
  color: #fff;
}
.blue{
  color: #00f;
}
.red{
  color: #f00;
}
.val-text{max-width: 100% !important;}
.badge-cnt{float: right; background: #5981ce; color: #fff; margin-top: -6px;
    margin-right: -10px; padding: 0 6px;}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
