<template>
  <transition name="fade">
    <LoaderTreeSpin v-if="spiner" />
    <div v-else class="form">
      <div class="form-header">
        <div class="form-title" v-if="title">{{title}}</div>
      </div>
      <div class="form-body">
        <div v-for="(group, ind) in fields" :key="ind" class="group">
          <div class="group-inner">
            <div class="group-name">{{group.name}}</div>
            <div v-for="(field, ind2) in group.fields" :key="ind2" class="form-field">
              <component :is="field.component" v-bind="field.conf"
                v-model="field.value" @update:modelValue="showBtn"></component>
            </div>
          </div>
        </div>
      </div>
      <a v-if="flagShowBtn" @click="submit"
        @keydown.f9="submit" class="btn-cross fix-right-bottom">{{btn_lbl}}</a>
      <transition name="fade">
        <div v-if="message.length > 0" class="form-answer"
          :class="{danger: !flagSuccess}">{{this.message}}</div>
      </transition>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

import SelectDef from '@/components/v2/cross/form/fields/SelectDef.vue';
import InputText from '@/components/v2/cross/form/fields/InputText.vue';
import CheckDef from '@/components/v2/cross/form/fields/CheckDef.vue';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';

export default {
  name: 'FormGroup',
  props: {
    title: {
      type: String,
      default: '',
    },
    init_action: {
      type: String,
      default: '',
    },
    btn_action: {
      type: String,
      default: '',
    },
    btn_lbl: {
      type: String,
      default: '',
    },
  },
  components: {
    SelectDef,
    InputText,
    CheckDef,
    LoaderTreeSpin,
  },
  data() {
    return {
      errors: [],
      spiner: true,
      fields: [],
      flagShowBtn: false,
      saveFields: {},

      message: '',
      flagSuccess: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.init_action) return;
      axios.get(this.init_action)
        .then((res) => {
          this.fields = res.data;
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    submit() {
      this.spiner = true;
      if (!this.validateReq()) {
        this.spiner = false;
        return false;
      }

      if (this.btn_action.length < 10) {
        this.spiner = false;
        return false;
      }

      axios.post(this.btn_action, { form: this.saveFields })
        .then((res) => {
          this.spiner = false;
          this.flagSuccess = res.data.success;
          this.message = res.data.answer;
          if (res.data.success === 1) {
            this.flagShowBtn = false;
            // his.$emit('answerForm', res.data);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      return true;
    },
    validateReq() {
      this.saveFields = {};
      let noError = true;
      let group = this.fields.length;
      while (group) {
        group -= 1;

        let i = this.fields[group].fields.length;
        while (i) {
          i -= 1;
          const el = this.fields[group].fields[i];
          if (el.conf.required
          && el.value === '') {
            this.fields[group].fields[i].conf.err = 'Поле не должно быть пустым';
            noError = false;
          }
          this.saveFields[el.conf.name] = el.value;
        }
      }

      return noError;
    },
    showBtn() {
      this.message = '';
      this.flagShowBtn = true;
    },
  },
};
</script>

<style scoped>
  .form-body{
    display: flex;
  }
  .group{
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
    min-width: 320px;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .group-name{
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 22px;
    text-transform: uppercase;
  }
  .group-inner{
    padding: 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow .25s;
    background-color: #fbfbfb;
  }
  .group-inner:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
  .btn-cross{
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    outline: 0;
    padding: 0 2rem;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;

    text-decoration: none;
    color: #fff;
    background-color: #009688;
    text-align: center;
    letter-spacing: .5px;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    -ms-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    cursor: pointer;

    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .btn-cross:hover{
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
  .btn-cross.fix-right-bottom{
    position: fixed;
    right: 10px;
    bottom: 10px;
  }
  .form-answer{
    position: fixed;
    right: 10px;
    bottom: 10px;
    background: #bbf7cb;
    border: 1px solid #8fbd9c;
    padding: 13px 46px;
  }
  .form-answer.danger {
    background: #bbf7cb;
    border: 1px solid #8fbd9c;
  }

  .r{
    margin-left: -15px;
    margin-right: -15px;
  }
  .c-6{
    width: 50%;
    padding-right: 5px;
    padding-left: 5px;
    min-width: 320px;
    display: inline-block;
    max-height: 320px;
    overflow-y: auto;
    vertical-align: top;
    box-sizing: border-box;
  }
  .c-4{
    width: calc(100% / 3);
    padding-right: 5px;
    padding-left: 5px;
    min-width: 320px;
    display: inline-block;
    max-height: 320px;
    vertical-align: top;
    box-sizing: border-box;
  }
  .form{
    text-align:left;
    margin:0 auto;
    background: #efefef;
    min-width: 360px;
  }
  .big{
    width: 800px;
  }
  .form-field{
    margin-bottom: 20px;
  }
  .form-title{
    margin-top: -10px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
  }
  .wrp-spiners{
    text-align: center;
    padding: 3px;
    background: #ddd;
    height: 36px;
    line-height: 36px;
  }
  .wrp-spiners span, .wrp-spiners i{
    padding: 0px 4px;
  }
  .big .form-field {
  }
  .w100{
    width: 100%;
    box-sizing: border-box;
  }
  .sub-fields{
    padding-left: 6px;
    padding-top: 15px;
    padding-bottom: 1px;
    border-left: 4px solid #aaa;
  }
</style>
