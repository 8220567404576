<template>
  <transition name="fade">
    <Loader v-if="spiner" />
    <table v-else class="table-info">
      <tbody>
        <tr v-for="(el, ind) in list" :key="ind" :class="{'child': el.level > 1}">
          <template v-if="el.val || el.val == 0">
            <td><i v-if="el.level > 1" class="fa fa-long-arrow-right" aria-hidden="true"></i>
            {{el.title}}</td><td>{{el.val}}</td>
          </template>
          <template v-else>
            <td class="head" colspan="2">{{el.title}}</td>
          </template>
        </tr>
      </tbody>
    </table>
  </transition>
</template>

<script>
import axios from 'axios';

import Loader from '@/components/v2/cross/loader/Loader.vue';

export default {
  name: 'ListLabel',
  components: {
    Loader,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      list: [],

      errors: [],
      spiner: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.action) return;
      axios.get(this.action)
        .then((res) => {
          this.list = res.data.list;
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.title{
  font-weight: bold;
  padding: 10px;
  min-width: 320px;
  color: #fff;
  background: #6b7bb9;
}
table{
  border-spacing: 0px;
  border-collapse: collapse;
}
.table-info{
  width: 100%;
}
.table-info td{
  text-align: left;
  border-top: 1px solid #ddd;
  line-height: 37px;
  padding: 0px 10px;
}
.table-info .odd{
  background:#eee;
}
.th{font-weight: bold}
.table-info tr:hover td{
  background: #dfdfdf;
}
.child td{
  background-color: #fff;
}
.child td:first-child{
  /* padding-left: 22px; */
}
td.head{
  padding-top: 6px;
  line-height: 24px;
  background-color: #e7e7e7;
}
</style>
