<template>
  <div class="cross-checkbox" :class="{checked: checked}" @click="check" @keydown.f7="check">
    <div class="chk-lbl">{{lbl}}</div>
  </div>
</template>

<script>
export default {
  name: 'CheckDef',
  props: {
    lbl: {
      type: String,
      default: '',
    },
    err: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      checked: 0,
    };
  },
  mounted() {
    if (this.modelValue === 1) {
      this.checked = 1;
    }
  },
  methods: {
    check() {
      if (this.checked === 1) {
        this.checked = 0;
      } else {
        this.checked = 1;
      }
      this.$emit('update:modelValue', this.checked);
      this.$emit('change');
    },
  },
};
</script>

<style scoped>
  .cross-checkbox{
    display: inline-block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
  }
  .cross-checkbox:before, .cross-checkbox:after{
    position: absolute;
    left:0px;
    top:0px;
    transform: translateX(5px) rotate(45deg) translateY(0px);

    content:"";
    width: 0px;
    height: 14px;
    border: 1px solid #d92323;
    transition: color 0.3s, top 0.3s, left 0.3s, transform 0.3s, height 0.3s;
  }
  .cross-checkbox:before{
    transform: translateX(5px) rotate(-45deg) translateY(0px);
  }

  .cross-checkbox.checked:after {
    transform: translateX(10px) rotate(30deg);
    border: 1px solid green;
  }
  .cross-checkbox.checked:before {
    transform: translateX(0px) rotate(-45deg);
    height: 7px;
    border: 1px solid green;
    left:3px;
    top:7px;
  }

  .chk-lbl{
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: #555;
  }
</style>
