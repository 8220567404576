<template>
  <div class="wb-input-text" :class="{focus: focus, 'error-field': error.length > 0}"
    @click="focusInput(id)" @keydown.f9="focusInput(id)">
    <div class="label" :class="{active: full || focus}" v-if="lbl">{{lbl}}</div>
    <label :for="`t${id}`">
      <input
        :id="`t${id}`"
        :class="{'req': req}"
        type="password"
        :value="modelValue"
        autocomplete="new-password"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"/>
    </label>
    <span v-if="error.length > 0" class="error-input">{{error}}</span>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    lbl: {
      type: String,
      default: '',
    },
    def: {
      type: String,
      default: '',
    },
    err: {
      type: String,
      default: '',
    },
    req: {
      type: Boolean,
      default: false,
    },
    ind: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: String,
    },
  },
  emits: ['update:modelValue', 'outError'],
  data() {
    return {
      show_ext_lbl: false,
      id: null,
      focus: false,
      errorText: 'start',
    };
  },
  mounted() {
    this.id = this.$.uid;
  },
  computed: {
    full() {
      return this.modelValue !== '';
    },
    error() {
      let txt = '';
      if (this.errorText === 'start') {
        txt = this.err;
      } else {
        txt = this.errorText;
      }
      return txt;
    },
  },
  methods: {
    onInput(event) {
      if (event.target.value.length !== 0) {
        this.errorText = '';
      } else {
        this.errorText = 'Поле не должно быть пустым';
      }
      this.$emit('update:modelValue', event.target.value);
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
    onFocus() {
      this.focus = true;
    },
    onBlur() {
      this.focus = false;
    },
    focusInput(id) {
      document.getElementById(`t${id}`).focus();
    },
  },
};
</script>

<style scoped>
  .wb-input-text{
    position: relative;
    margin-left: 0px;
    padding-top: 16px;
  }
  .label{
    color: #909090;
    display:inline-block;
    position: absolute;
    top: 24px; left: 0px;
    transition: top 0.3s, left 0.3s, font-size 0.3s, color 0.3s;
  }
  .label.active{
    top: 0px; left: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
  }
  .focus .label{
    color: #345F90;
  }
  .focus input{
    border-bottom-color: #345F90;
  }
  input {
    display: inline-block;
    width: 100%;
    padding: 12px 2px 8px;
    border: none;
    border-bottom: 1px solid #aaa;
    font-size: 16px;
    line-height: 18px;
    outline: none;
    box-sizing: border-box;
    background: transparent;
    transition: border-bottom-color 0.3s, background-color 0.3s;
  }
  input:hover{background-color: #e8e8e8;}
  .focus input:hover{background-color: transparent;}
  .req{}
  .error-field{

  }
  .error-field .label{color: #b33d3d;}
  .error-field input{
    border-bottom-color: #b33d3d;
  }

  .error-input{
    color: #b33d3d;
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    bottom: -16px;
    left: 0px;
  }
  .error-input::-webkit-input-placeholder {color: #e57272;}
  .error-input:-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input::-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input:-ms-input-placeholder {color: #e57272;}
  .error-input::-ms-input-placeholder {color: #e57272;}
  .error-input::placeholder {color: #e57272;}
</style>
