<template>
  <LinkHint v-if="showMissing"
    :animate="true"
    icon="fa-fire"
    type="fire"
    hint="Необходимо донастроить товары для их отображения в системе"
    align="br"
    path="/scm/product/set"
    :query="{ mode: 1 }"
  />

  <div class="gen-header">
    <WBNavigation @showContextMenu="showContextMenu = true" />
  </div>

  <router-view :key="stock" />

  <div class="context-menu" v-if="showContextMenu"
    @click="closeDialog(1)"
    @keydown.f7="closeDialog(1)">
    <div class="context-menu--body" @click.stop>
      <a v-for="(item, ind) in gettersAuthData.qmenu" :key="ind"
        class="context-menu--item"
        @click.prevent="showActForm(item.url)"
        @keydown.f7.prevent="showActForm(item.url)">
          <i class="fa" :class="item.icon" aria-hidden="true"></i>
          {{item.name}}
      </a>
    </div>
  </div>
  <div v-if="showContextForm" class="context-menu modal-form"
    @click="closeDialog(2)"
    @keydown.f7="closeDialog(2)">
    <div class="context-menu--body"  @click.stop >
      <div v-if="answerForm.length" class="form-answer">{{answerForm}}</div>
      <Form v-else :title="titleForm"
      :btn="btnForm"
      class="center-center"
      :fields="dataForm"
      :action="actionForm"
      method="post"
      @answerForm="formAnswer" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Form from '@/components/atoms/Form.vue';
import LinkHint from '@/components/atoms/LinkHint.vue';
import WBNavigation from '@/components/parts/WBNavigation.vue';

export default {
  name: 'App',
  components: {
    WBNavigation, Form, LinkHint,
  },
  data() {
    return {
      errors: [],
      showContextMenu: false,
      showContextForm: false,
      titleForm: 'Форма',
      answerForm: '',
      actionForm: '',
      btnForm: 'Отправить',
      dataForm: {},
    };
  },
  computed: {
    ...mapGetters('auth', {
      gettersAuthData: 'getAuthData',
      showMissing: 'getShowMissing',
    }),
    stock() {
      let val = 0;
      if (this.gettersAuthData.stocks) {
        val = this.gettersAuthData.stocks.value.val;
      }
      return val;
    },
  },
  methods: {
    showActForm(action) {
      axios.get(action)
        .then((res) => {
          this.dataForm = res.data.pack;
          this.titleForm = res.data.form_info.name;
          this.actionForm = action;
          this.btnForm = res.data.form_info.btn;
          this.showContextForm = true;
          this.showContextMenu = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    formAnswer(data) {
      if (data.answer.length) {
        this.answerForm = data.answer;
      }
    },
    closeDialog(step) {
      if (step === 1) {
        this.showContextMenu = false;
      } else {
        this.showContextForm = false;
        this.answerForm = '';
      }
    },
  },
};
</script>

<style>
.gen-header{
  position: fixed;
  background: #fff;
  top: 0px;
  left: 0px;
  z-index: 20;
  height: 51px;
  width: 100%;
}
.context-menu{
  text-align: center;
}
body{
  margin: 0px;
  padding: 0px;
}

a{text-decoration:none;}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 50px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.wb-btn{
  border-radius:4px; padding:12px 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.wb-btn-sh{
  border-radius: 4px;
  padding:8px 20px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.wb-btn-success{
  background: #098e04; /* Для старых браузеров - без градиента */
  background: -moz-linear-gradient(top,  #8bc465 0%, #098e04 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top,
  left bottom, color-stop(0%,#8bc465), color-stop(100%,#098e04));
  background: -webkit-linear-gradient(top,  #8bc465a 0%,#098e04 100%);
  background: -o-linear-gradient(top,  #8bc465 0%,#098e04 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #8bc465 0%,#098e04 100%); /* IE10+ */
  background: linear-gradient(to bottom, #8bc465 0%,#098e04 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc465',
  endColorstr='#098e04',GradientType=0 );
}
.wb-btn-success:hover{
  background: #8bc465; /* Для старых браузеров - без градиента */
  background: -moz-linear-gradient(top,  #098e04 0%, #8bc465 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top,
  left bottom, color-stop(0%,#098e04), color-stop(100%,#8bc465));
  background: -webkit-linear-gradient(top, #098e04 0%,#8bc465a 100%);
  background: -o-linear-gradient(top,  #098e04 0%,#8bc465 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #098e04 0%,#8bc465 100%); /* IE10+ */
  background: linear-gradient(to bottom, #098e04 0%,#8bc465 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#098e04',
  endColorstr='#8bc465',GradientType=0 );
}
.wb-btn-info{
  background: linear-gradient(to bottom, #65b7c4 0%, #045a8e 100%);
}
.wb-btn-info:hover{
  background: linear-gradient(to bottom, #045a8e 0%, #65b7c4 100%);
}
.wb-btn-danger{
  background: linear-gradient(to bottom, #c46565 0%, #8e0404 100%);
}
.wb-btn-danger:hover{
  background: linear-gradient(to bottom, #8e0404 0%, #c46565 100%);
}

.wb-btn-def{
  background: linear-gradient(to bottom, #fff 0%, #bbb 100%);
  color: #000;
}
.wb-btn-def:hover{
  background: linear-gradient(to bottom, #bbb 0%, #fff 100%);
}

.btn-context-menu{
  position: fixed;
  right: 0px;
  top: 0px;
  background: #5981ce;
  color: #fff;
  padding: 13px;
  font-size: 20px;
  cursor:pointer;
}
.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background:#d2d2d2;
  width:auto;
  display:inline-block;
  text-align:left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
}
.context-menu--item{
  display:block;
  line-height: 20px;
  cursor:pointer;
  padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

/* W3C standard сейчас только для Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #848484 #d7d7d7;
  }
  /* для Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #d7d7d7;
    width:3px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #848484;
  /*  border-radius: 5px; */
    border: 3px solid #d7d7d7;
  }

.instrumental{
  position: fixed;
  left:0px;
  bottom:0px;
  background: #aaa;
  width: 100%;
}
.instrumental .btn.btn-add{
  background: #5981ce;
  color: #fff;
  padding: 6px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.instrumental .btn.btn-add:hover{
  background: #3b70d7;
  padding: 8px;
}
.instrumental .btn.btn-add span, .instrumental .btn.btn-add i{
  vertical-align: middle;
}

.bg-loader{
  background: #efefef;
  color: #2c3e50;
}
.text-warn-1{
  background: #efcccc;
  color: #852121;
  padding: 12px;
  /* border: 1px solid #e79c9c; */
}
.text-warn-1 a{
  font-weight: bold;
  text-decoration: underline;
}
.text-warn-1 a:hover{
  text-decoration: none;
}

.text-info-1{
  background: #ccefcd;
  color: #4c8521;
  padding: 12px;
  border: 1px solid #bcd5aa;
}

@media print {
  /* здесь будут стили для печати */
  /* body .not_print {display: none } */
  .list-table{display:none; }
  .context-menu.modal-form{
    line-height:0px;
    text-align: left;
    position:absolute;
  }
  .context-menu--body{padding:40px;}
  .context-menu.modal-form .list-table{
    display:block;
  }
  .not_print{display: none;}
  @page {
    size: auto;
    margin: 0mm;
  }
}

.c-3{
  width: calc(100% / 3);
  display: inline-block;
  vertical-align: top;
  padding-left: 0px;
  padding-right: 2px;
  box-sizing: border-box;
  margin-bottom: 10px;

  position: relative;
  z-index: 2;
}

h1{
  margin-right: 2px;
  line-height: 1.42857143;
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 0px;
  border-bottom: 1px solid #5981ce;
  width: 100%;
}
h1 span {
  display: inline-block;
  background: #5981ce;
  padding: 2px 30px;
}
h1 + .wrp-tabs{
  margin-top:-15px;
}
h1 + .wrp-tabs .btn-tab{
  border-radius: 0 0 4px 4px;
}
h1 + .wrp-tabs .tab-content{
  border-top: none;
}
.modal-form{text-align: center;}

.odd{
  background: #f1f1f1;
}

.fa-sort-amount-asc, .fa-sort-amount-desc{
  color: #749be5;
  margin-right: 6px;
}
.table-iface .hover.active {
  background: #dfdddd;
}
body .table-iface th.hover.active:hover{
  background: #ccc;
}
.scm h1{margin-bottom: 0px;}
.mb50{margin-bottom: 50px;}
html {
  /* overflow-y: scroll; */
}

.pagination{
  margin-top: -15px;
  float: right;
  position: relative;
  z-index: 1;
}
.pagination .pag-el{
  display: inline-block;
  vertical-align: top;
  background-color: #749be5;
  border: 1px solid #4c7fe1;
  color: #fff;
  cursor: pointer;
  padding:2px 6px;

  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;

  transition: background-color 0.5s;
}
.pagination .pag-el:hover{
  background-color: #4c7fe1;
}
.pagination .pag-el.active{
  background-color: #44b752;
  border: 1px solid #2c9539;
}

.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
  text-align: center;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}
.progress .buffer-lbl{
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 20px;
}
.progress .buffer-lbl.white-text{ color: #fff;}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
    background-color: #5baade;
}
.progress-bar-black {
    background-color: #757575;
}
.progress-bar-warning {
    background-color: #f0ad4e;
}
.progress-bar-danger {
    background-color: #d9534f;
}
.progress-bar-success {
    background-color: #5cb85c;
}
</style>
