<template>
  <transition name="fade">
    <Loader v-if="spiner" />
    <div class="main" v-else>
      <aside class="left-list">
        <div class="list">
          <button class="btn-add" @click="showForm">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
          <div class="list-el" v-for="(el, ind) in list" :key="ind"
            :class="{active: el.id == currIndex}"
            @click="setTabs(el.id)"
            @keydown.f7="setTabs(el.id)">
            <div class="el-title">
              <strong>{{el.name}}</strong><br />
              <span class="info-time">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                {{el.time_synh}}
              </span>
              <span class="service"><i class="fa fa-circle" aria-hidden="true"
              :class="{red: el.active == 0}"></i>&nbsp;{{el.controller_name}}</span>
            </div>
          </div>
        </div>
      </aside>
      <section>
        <div class="column-right">
          <div class="col s12">
            <MenuTab
              class="level2"
              :els="tabs"
              :key="currIndex"
              @activeTab="selTab">
              <component :is="currentTab" v-bind="currOptions"></component>
            </MenuTab>
          </div>
        </div>
      </section>
    </div>
  </transition>
  <transition name="fade">
    <ModalRight v-show="flagModalRight"
      @close="flagModalRight = false;">
      <Form v-if="flagModalRight"
        :title="form.title"
        :init_action="form.initAction"
        :btn_action="form.btnAction"
        :btn_lbl="form.btnLabel"
        @answer="addTabs"></Form>
    </ModalRight>
  </transition>
</template>

<script>
import axios from 'axios';

import Loader from '@/components/v2/cross/loader/Loader.vue';
import MenuTab from '@/components/v2/cross/menu/MenuTab.vue';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import Form from '@/components/v2/cross/form/Form.vue';
import ListLabel from '@/components/v2/cross/list/ListLabel.vue';
import ListLabelGroup from '@/components/v2/cross/list/ListLabelGroup.vue';

export default {
  name: 'ServicesCustom',
  components: {
    Loader, MenuTab, ModalRight, Form, ListLabel, ListLabelGroup,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      list: [],
      errors: [],
      spiner: true,

      currentTab: '',
      currOptions: {},
      currIndex: -1,
      tabs: [],

      flagModalRight: false,
      form: {
        title: 'Добавление сервиса',
        initAction: '/v2/integration/get-fields',
        btnAction: '/v2/integration/add',
        btnLabel: 'Добавить',
      },

      loader: 1,
      // currIndex: 0,
      // currIndex2: 0,

      // instrumental: [],
      showContextForm: false,
      answerForm: '',
      actionType: 'add',
      actionElem: 0,

      loadModal: 0,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    currentTabComponent() {
      return `${this.currentTab}`;
    },
    currentOptions() {
      return this.currOptions;
    },
  },
  methods: {
    init() {
      if (!this.action) return;
      axios.get(this.action)
        .then((res) => {
          this.list = res.data.list;
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    selTab(tab) {
      this.currentTab = tab.name;
      this.currOptions = tab.options;
    },
    setTabs(val) {
      this.currIndex = val;
      axios.get('/v2/integration/for-tabs', { params: { val } })
        .then((res) => {
          if (res.data.success === 1) {
            this.tabs = res.data.tabs;
            this.currentTab = res.data.component.name;
            this.currOptions = res.data.component.options;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showForm() {
      this.flagModalRight = true;
    },
    /*
    selIndex(ind) {
      this.currIndex = ind;
      this.currIndex2 = 0;
      axios.get(this.list[this.currIndex].list[this.currIndex2].action)
        .then((res) => {
          this.activeComponent = res.data.component.name;
          this.activeOptions = res.data.component.options;
          this.currIndex2 = 50;
        });
    },
    selIndex2(ind) {
      axios.get(this.list[this.currIndex].list[ind].action)
        .then((res) => {
          this.activeComponent = res.data.component.name;
          this.activeOptions = res.data.component.options;
          this.currIndex2 = ind;
        });
    },
    componentAnswer(data) {
      if (data.success === 1) {
        if (data.action === 'del') {
          this.list.splice(this.currIndex, 1);
        } else {
          axios.get(data.action)
            .then((res) => {
              this.activeComponent = res.data.component.name;
              this.activeOptions = res.data.component.options;
            });
        }
      }
    },
    */
    componentUpdate(el, ind) {
      this.activeOptions.list[ind] = el;
    },
    closeModal() {
      this.showContextForm = false;
      this.answerForm = '';
    },
    formAnswer(data) {
      this.answerForm = data.answer;
      if (this.actionType === 'add') {
        this.list.push(data.elem);
      } else if (this.actionType === 'chg') {
        // this.items.items.splice(this.actionElem, 1, data.elem);
        console.log('Изменение заблокировано');
      }
    },
    addTabs(data) {
      console.log(data);
      if (data.act && data.act === 'add') {
        this.list.unshift(data.elem);
      }
    },
  },
};
</script>

<style scoped>
.fa-circle{
  color: #4caf50;
  transition: color 0.25s;
}
.fa-circle.red{
  color: #e57373;
}
.service{
  text-transform: lowercase;
}
.btn-add{
  position: sticky;
  top: 0px;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  padding: 8px 20px;
  width: 100%;
  background-color: #fff;
  color: #345F99;
  cursor: pointer;
  font-size: 18px; line-height: 20px;
  transition: background-color 0.5s, color 0.5s;
}
.btn-add:hover{
  background-color: #ddd;
}
.main{
  padding-left: 240px;
  box-sizing: inherit;
}
.left-list{
  position: fixed;
  width: 100px;
  left: 180px;
  z-index: 1;
  height: auto;
}
.list{
  width: 240px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 2px;
  overflow: hidden;
  position: fixed;
  left:0px;

  font-size: 14px;
  height: calc(100% + -147px);

  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s;
  background-color: #fbfbfb;
}

.list:hover{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  overflow-y: auto;
}

.list-el{
  background-color: #fff;
  line-height: 1.5rem;
  padding: 10px 20px;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.25s, color 0.25s;
}
.list-el:hover{
  background-color: #ddd;
}
.list-el.active{
  background-color: #345F90;
  color: #fbfbfb;
}
.el-title{
  font-size: 1.0rem;
  padding: 0;
  margin: 0;
  font-weight: 500;
}
.column-right{
  width: 98%;
  padding: 0 0.5rem;
  margin: 0 auto;
  max-width: 100% !important;
}
.info-time{
  float: right;
  font-size: 13px; line-height: 14px;
  margin-top: 6.5px;
}
</style>
