<template>
  <div>
    <transition name="fade">
      <div v-if="showBtnMultySelect" class="panel-btn"
        @click="btnSelAll"
        @keydown.f10="btnSelAll">Выбрать</div>
    </transition>
    <LoaderTreeSpin v-if="loader" />
    <div v-else-if="items.length == 1 && !items[0].lbl">
      <a href="/scm/setting/stock-link">Необходимо привязать категории к выбранному складу >>></a>
    </div>
    <div v-else class="ierarch">
      <div :class="`level-${el.level}`" v-for="(el, ind) in items" :key="ind">
        <div class="item" v-if="el.show"
          @click="toggleChild(ind)"
          @keydown.f9="toggleChild(0)"
          :class="{'ierarh-border': el.level > 0}">
          <div v-if="el.level > 0" class="squad-level"></div>
          <template v-if="mode !== 'checkbox'">
            <template v-if="el.child || dynamic && el.cnt != 0">
              <i v-if="el.open" class="fa fa-folder-open" aria-hidden="true"></i>
              <i v-else class="fa fa-folder" aria-hidden="true"></i>
            </template>
            <div v-else class="empty-squad"><i class="fa fa-folder " aria-hidden="true"></i></div>
          </template>
          <span class="el">
            <span v-if="mode === 'checkbox'" style="float:left; display:block;">
              <template v-if="el.child || dynamic && el.cnt != 0">
                <i v-if="el.open" class="fa fa-folder-open" aria-hidden="true"></i>
                <i v-else class="fa fa-folder" aria-hidden="true"></i>
              </template>
              <span v-else class="empty-squad">
                <i class="fa fa-folder " aria-hidden="true"></i>
              </span>
            </span>
            <div v-if="mode === 'checkbox'" class="squad"
              @click.stop="check(ind)"
              @keydown.f8="check(0)">
              <span v-if="el.chk">&#10004;</span>
            </div>
            <span style="white-space:nowrap;"
              :title="'('+el.cnt+') '+el.lbl">{{el.lbl}} ({{el.cnt}}) </span>
            <div v-if="mode === 'select'" class="btn-select" title="выбрать"
              href="javascript:void(0)"
              @click.prevent.stop="sel(el)"
              @keydown.f10="sel(0)">
              <i class="fa fa-podcast" aria-hidden="true"></i>
            </div>
            <div v-if="mode === 'selectmulty'" class="btn-select"
              :class="{chk: el.chk}"
              title="выбрать"
              href="javascript:void(0)"
              @click.prevent.stop="selOne(ind)"
              @keydown.f10="selOne(ind)">
              <i class="fa fa-podcast" aria-hidden="true"></i>
            </div>
            <div class="gProd" v-if="el.child_custom.length > 0 && el.open == true"
              @click.stop>
              <div v-for="(prod, ind2) in el.child_custom" :key="ind2"
                :title="'('+prod.cnt+') '+prod.lbl">
                <div v-if="el.level > 0" class="squad-level"></div>
                <span class="empty-squad" style="flaot:left">
                  <i class="fa fa-cube" aria-hidden="true"></i>
                </span>
                <div v-if="mode === 'checkbox'" class="squad"
                  @click.stop="checkProd(ind, ind2)"
                  @keydown.f8="checkProd(0)">
                  <span v-if="prod.chk">&#10004;</span>
                </div>
                {{prod.lbl}} ({{prod.cnt}})
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';

export default {
  name: 'ListIerarchDef',
  components: {
    LoaderTreeSpin,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: '',
    },
    autorule: {
      type: Number,
      default: 0,
    },
    stock: {
      type: Number,
      default: 0,
    },
    modeload: {
      type: Number,
      default: 0,
    },
    selectel: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loader: true,
      items: [],

      level: 0,
      flag: 0,
      open: false,

      chk: false,

      showBtnMultySelect: false,
      errors: [],
    };
  },
  computed: {
  },
  mounted() {
    this.init(this.modeload);
  },
  methods: {
    init(subload) {
      const customParam = { autorule: this.autorule };
      if (this.stock > 0) {
        customParam.stock = this.stock;
      }

      axios.get(this.action, { params: customParam })
        .then((res) => {
          if (res.data.success === 1) {
            this.items = res.data.list;

            if (this.selectel.length) {
              this.selectel.forEach((catID) => {
                this.items.forEach((item2, i2) => {
                  if (catID === item2.val) {
                    this.items[i2].chk = true;
                  }
                });
              });
            }

            if (this.autorule) {
              this.$emit('sel', this.items);
            }
          }
          this.loader = false;
          if (subload) {
            this.check(-1);
          }
        })
        .catch((e) => {
          this.loader = false;
          this.errors.push(e);
        });
    },
    toggleChild(ind) {
      if (this.items[ind].child === false) {
        // только если есть галка на получение таких товаров, получить эту настройку
        // запрос на получение товаров
        if (this.items[ind].child_custom.length > 0) {
          if (this.items[ind].open === true) {
            this.items[ind].open = false;
          } else {
            this.items[ind].open = true;
          }
          return;
        }
        axios.get('/v2/product/get-custom-by-category', {
          params: {
            val: this.items[ind].val,
            stock: this.stock,
            mode: this.$route.query.mode ? this.$route.query.mode : 0,
          },
        })
          .then((res) => {
            // console.log(res.data);
            this.items[ind].open = true;
            this.items[ind].child_custom = res.data;
          })
          .catch((e) => {
            this.errors.push(e);
          });

        return;
      }
      this.level = this.items[ind].level;
      this.flag = 0;
      this.open = this.items[ind].open;
      let BreakException;
      try {
        this.items.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level === item.level) {
              throw BreakException;
            }

            // изменить show
            if (this.open === true) {
              this.items[i].show = false;
              this.items[i].open = false;
            } else if ((this.level + 1) === item.level) {
              this.items[i].show = true;
            }
          }

          if (ind === i) {
            this.flag = 1;
            // изменить open
            this.items[i].open = !this.items[i].open;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    check(ind) {
      this.checkChild(ind);
      this.$emit('sel', this.items);
    },
    checkProd(ind, ind2) {
      let i = this.items;
      while (i) {
        i -= 1;
        this.items[ind].chk = false;
      }

      if (this.items[ind].child_custom[ind2].chk === true) {
        this.items[ind].child_custom[ind2].chk = false;
      } else {
        this.items[ind].child_custom[ind2].chk = true;
      }
      this.$emit('selprod', {
        list: this.items[ind].child_custom,
        cat: this.items[ind].val,
      });
    },
    checkChild(ind) {
      if (ind >= 0) {
        this.level = this.items[ind].level;
        this.chk = this.items[ind].chk;
      }
      this.flag = 0;
      let BreakException;
      try {
        this.items.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level >= item.level) {
              // throw BreakException;
              this.flag = 0;
            }

            if (this.flag === 1) {
              if (this.chk) {
                this.items[i].chk = false;
              } else {
                this.items[i].chk = true;
              }
            }
          }

          if (ind === i) {
            this.flag = 1;
            if (this.chk) {
              this.items[i].chk = false;
            } else {
              this.items[i].chk = true;
            }
          }
          // скрыть все подгруженные товары иерархии категорий
          if (this.items[i].child === false) {
            this.items[i].open = false;
            this.items[ind].child_custom.forEach((item, i) => {
              this.items[ind].child_custom[i].chk = false;
            });
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    sel(el) {
      this.$emit('sel', el);
    },
    selOne(ind) {
      this.checkChild(ind);
      this.showBtnMultySelect = true;
    },
    btnSelAll() {
      const list = [];
      this.items.forEach((item) => {
        if (item.chk) {
          list.push(item.val);
        }
      });

      this.$emit('sel', list);
    },
  },
};
</script>

<style scoped>
.el{
  display: inline;
}
.level-1{margin-left: 5px}
.level-2{margin-left: 25px}
.level-3{margin-left: 45px}
.level-4{margin-left: 65px}
.level-5{margin-left: 85px}
.level-6{margin-left: 105px}
.level-7{margin-left: 125px}
.level-8{margin-left: 145px}
.level-9{margin-left: 165px}
.level-10{margin-left: 185px}

.gProd{
  margin-top: 2px;
  background-color: #fff;
  cursor: default;
}
.wb-c:hover .gProd{background-color: #f1f1f1}
.gProd > div{padding-bottom: 4px;}

.gProd{padding-left: 23px; margin-left: -1px;}
.gProd > div{ border-left: 1px solid #000; white-space:nowrap;}

.zhir{font-weight: bold; margin-right: 5px;}
.item{
  display: inline-block;
  cursor: pointer;
}
.item .fa-folder, .item .fa-folder-open{
  margin-right: 3px;
  float:left;
}
.item .fa-folder{ color: green; }
.item .fa-folder-open{ color: #b91717; }
.item .empty-squad .fa-folder{ color: gray; }

.empty-squad{width: 12.5px; height:16px; display: inline-block; margin-right: 5px;}
.squad-level{
  width: 10px;
  height: 20px;
  border-left: 1px solid #000;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  position:relative;
  float:left;
  left:-1px;
}
.squad-level:before{
  content: '';
  display: block;
  width: 100%;
  border-top: 1px solid #000;
  position: absolute;
  top: 36%;
  left: 0px;
}
.ierarh-border{
  border-left: 1px solid #000;
}
.fa-cube{margin-right: 4px;}
.r-cnt{position: absolute; right: 0px; top: -40px;}

.btns{float: right}
.btns a{margin-left: 10px;}
.col{display: inline-block;}
.prods{margin-left: 16px; margin-top:2px; margin-bottom: 2px;}

.prod-el{padding: 3px 0px 4px;}
.prod-el:hover{background: #dbdbdb;}

.btn-select{display: inline-block;}
.btn-select i.fa{color: #5981ce;}
.btn-select:hover i.fa{color: #3569cf}
.btn-select.chk i.fa, .btn-select.chk:hover i.fa{color: #469354;}

.squad{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #999;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  margin-right: 4px;
  float: left;
}
.squad + span {
  display: block;
  margin-left: 24px;
  line-height: 22px;
}

.level-1 .squad + span,
.level-2 .squad + span,
.level-3 .squad + span,
.level-4 .squad + span,
.level-5 .squad + span,
.level-6 .squad + span,
.level-7 .squad + span,
.level-8 .squad + span,
.level-9 .squad + span,
.level-10 .squad + span{margin-left: 39px;}

.panel-btn{
  padding: 10px 10px;
  background: #5981ce;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  position:absolute; left:0px; bottom:0px;
  width: 100%;
}
</style>
