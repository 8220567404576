<template>
  <Loader v-if="loaderContent" />
  <template v-else>
    <h1 class="v2">
      <span>{{headerName}}</span>
      <div class="btn-tab2">
        <SelectCustom
          v-model="mode.value"
          class="small-size"
          :lbl="mode.lbl"
          :options="mode.options"
          :def="mode.lbl"
          :active="!modeShow"
          @change="refreshBlocks"
        />
      </div>
    </h1>
    <div class="filter-depend" v-if="showFilterDepend">
      <template v-for="(el, ind) in filter" :key="ind">
        <SelectLoad v-if="el.type == 'depend'"
          v-model="el.value"
          class="small-size"
          :def="el.placeholder"
          :lbl="el.lbl"
          :action="el.action"
          :params="{
            stock: stock,
            mode: mode.value,
          }"
          :ind="ind"
          @change="setFilterFields(ind)"
        />
        <br v-if="el.type == 'separated'" />
      </template>
    </div>
    <div class="wrapper-blocks">
      <div class="block1" :class="{active: modeShow}">
        <CategoryFunctional
          :stock="gettersAuthData.stocks.value.val"
          :NeedCheckbox="modeShow"
          :mode="mode.value"
          :filter="0"
          :key="mode.value.val"
          :action="actionCategory"
          @transferCats="transferToList"
          @changeShow="changeShow"
        />
      </div>
      <div class="wrapper-second-window">
        <div class="block2" :class="{closed: modeShow}">
          <ListManage class="stock-set"
            :action="actionProduct"
            :outerFilter="outerFilter"
            :key="keyCats"
            @gotIdsOperations="transferToOperations"
          />
        </div>
        <div class="block3"
          :class="{active: (activedOperations && modeShow === 0)
            || (activedCats && modeShow === 1)}">
          <Operations
            :field="this.operations"
            :stock="this.stock"
            :cats="this.cats"
            :prods="idsOperations"
            :action="this.actionCategory"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import SelectLoad from '@/components/v2/cross/select/SelectLoad.vue';
import SelectCustom from '@/components/v2/serj/Select.vue';
// import ConfirmCrossDef from '@/components/v2/cross/confirm/Def.vue';
// import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import ListManage from '@/components/v2/cross/list/ListManage.vue';
import CategoryFunctional from '@/components/v2/serj/CategoryFunctional.vue';
import Loader from '@/components/loader/LoaderTreeSpin.vue';
import Operations from '@/components/v2/serj/OperationsManage.vue';

export default {
  name: 'IfaceStockSet',
  components: {
    SelectLoad,
    CategoryFunctional,
    Operations,
    // ConfirmCrossDef,
    ListManage,
    // ModalRight,
    SelectCustom,
    Loader,
  },
  data() {
    return {
      headerName: '',

      idsOperations: [],
      operations: [],
      actionOperations: '',

      modeShow: 0,
      actionCategory: '/v2/category/list-by-stock-prod',
      actionProduct: '/v2/product/list-by-categories',
      outerFilter: {},
      stock: 0,

      keyCats: '',
      cats: [],
      loaderContent: true,
      activedblock3: 0,
      flagModalRight: false,
      modalTitle: 'Experiment',
      fieldParams:
      {
        button: 'button-td',
        icon: 'fa-bars',
        list: [
          {
            icon: 'fa-cogs',
            title: 'Настроить',
            type: 'GL',
            action: '/scm/product/upd/9729317',
            params: {
              id: 0,
            },
          },
        ],
      },
      filter: [],
      mode: {},
      extFields:
      {
        stock: {
          val: 1017,
          lbl: 'Склад 2',
          flag_main: 0,
          flag: 0,
          type: 0,
        },
        controll: 0,
      },
      tabs: [],
      errors: [],
    };
  },
  computed: {
    ...mapGetters('auth', {
      gettersAuthData: 'getAuthData',
    }),
    showFilterDepend() {
      return this.mode.value.filter;
    },
    activedOperations() {
      return this.idsOperations.length > 0;
    },
    activedCats() {
      return this.cats.length > 0;
    },
  },
  created() {
    this.stock = this.gettersAuthData.stocks.value.val;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loaderContent = true;
      axios.get('/v2/iface/setting-multi', { params: { stock: this.stock } })
        .then((res) => {
          if (res.data.success === 1) {
            this.headerName = res.data.name;
            this.mode = res.data.mode;
            this.filter = res.data.filter;
            this.loaderContent = false;
            this.operations = res.data.operations;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    transferToOperations(ids) {
      this.idsOperations = ids;
    },
    changeShow(mode) {
      this.modeShow = mode;
    },
    transferToList(cats) {
      this.keyCats = cats.join('');
      this.outerFilter = {
        stock: this.gettersAuthData.stocks.value.val,
        cats,
        extFilter: [],
        mode: this.mode.value.val,
      };
    },
    selTab(ind) {
      this.loader = true;
      let len = this.tabs.length;
      while (len) {
        len -= 1;
        if (len === ind) {
          this.tabs[len].show = true;
        } else {
          this.tabs[len].show = false;
        }
      }
      this.loader = false;
    },

    setFilterFields(ind) {
      console.log(this.filter[ind].name);
    },
  },
};
</script>

<style scoped>
.wrapper-second-window {
  /* display:flex;  */
  position: relative;
  width: 100%;
}
h1.v2{
  border-bottom: 1px solid #67bf50;
  margin-bottom: 0px;
}
h1.v2 span{
  background: transparent;
  padding: 2px 4px;
  color: #000;
}
.filter-depend {
  /* background: #f6f8fa; */
  /* border: 1px solid #dadee0; */
  padding: 5px 5px;
  /* margin-bottom: 5px; */
}
.small-size {
}
.wrapper-blocks {
  position: relative;
  display: flex;
  height: calc(100vh - 78px);
  background-color: #f6f8fa;
}

.filter-depend + .wrapper-blocks{
  height: calc(100vh - 134px);
}

.block1 {
  position: relative;
  min-width: 20%;
  max-width: 50%;
  overflow-y: auto;

  background-color: #f6f8fa;
  border: 1px solid #dadee0;
}
.block1.active {
  max-width: 100%;
  width: 100%;
  transition: max-width 0.3s;
}
.block2 {
  overflow-y:auto;
  background-color: #f6f8fa;
  border: 1px solid #dadee0;
  border-left: none;
}
.block2.closed {
  width: 0%;
}
.block3 {
  display: block;
  z-index: 1;
  height: 0%;
  overflow-y: auto;
  background: white;
  color: #333;
  width: 100%;
  transition: max-width 0.3s ease-in-out;
}
.block3.active {
  height: 100%;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
}
.btn-tab2{
  position: absolute;
  right: 0;
  display: inline-block;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}
.btn-tab:hover{background:#eee;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
</style>
