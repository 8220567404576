<template>
  <div style="background: #efefef;">
    <div class="wrp-wrp-tabs">
      <div class="wrp-tabs">
        <div
          v-for="(tab, ind) in els"
          :key="ind"
          ref="tabs"
          :class="['btn-tab', { active: activeTab === ind }]"
          @click="selTab(tab, ind)"
          @keydown.f7="selTab(tab, ind)"
        >
          {{ tab.lbl }}
        </div>
        <div class="active-bg"
          :style="{transform: `translateX(${activePosition}px)`, width: `${activeWidth}px`}"></div>
      </div>
    </div>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MenuTab',
  props: {
    els: {
      type: Array,
      required: true,
      default: () => [],
    },
    val: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      activePosition: 0,
      activeTab: 0,
      activeWidth: 110,
    };
  },
  mounted() {
    this.activeTab = 0;
  },
  methods: {
    selTab(tab, ind) {
      const el = this.$refs.tabs[ind];
      this.activeWidth = el.offsetWidth;
      this.activePosition = el.offsetLeft;
      this.activeTab = ind;

      this.$emit('active-tab', tab);
    },
  },
};
</script>

<style scoped>
.wrp-menu-tab{
  /* height: calc(100% - 38px); */
}
.wrp-wrp-tabs{
  width: 100%;
  background: #345f9012;
  overflow: hidden;
  margin-bottom: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 5px 0px;
}
.tab-content{
  height: calc(100vh - 137px);
  overflow: hidden;
}
.tab-content:hover{
  overflow-y: auto;
}
.btn-tab{
  position: relative;
  z-index: 1;
  color: #345F99;
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 6px 12px;
  transition: color 250ms ease-in-out, background-color 0.3s;
}
.btn-tab:hover{background-color:#d0d0d0;}
.btn-tab:hover.active{background-color:transparent;}
.btn-tab.active {
  color: #fff;
}

.active-bg {
  content: "";
  width: 122px;
  background: #345F90;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 250ms ease-in-out;
  border-radius: 3px 3px 0px 0px;
  z-index: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 5px 0px;
}
.wrp-tabs {
  display: block;
  float:left;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
  background: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.level2 .tab-content{
  height: calc(100vh - 196px);
  overflow: hidden;
}
.level2 .tab-content:hover{
  overflow-y: auto;
}
</style>
