<template>
  <div class="scm">
    <h1><span>Дашборд. Распределение запасов по статусам буферов</span></h1>
    <transition name="fade">
      <Loader v-if="loaderGlobal" />
      <div v-else>
        <Filter v-if="filter.type"
          :options = "filter"
          @change-field="showGraph" />

        <transition name="fade">
          <Loader v-if="loader" />
          <div v-else class="r">
            <div v-for="(el, ind) in diagramms" :key="ind" class="c-3"
            :class="{odd: ind%2 == 0}">
              <template v-if="el.type == 'chotometr'">
                <div class="diag-title">{{el.lbl}}</div>
                <Chotometr
                  :lbl="el.lbl"
                  :name="el.name"
                  :info="el.info"
                  :unitdopinfo="el.extinfo"
                  @click-sector="showSector"/>
              </template>
              <template v-else-if="el.type == 'lifecolumn'">
                <div class="diag-title">{{el.lbl}}</div>
                <LifeColumn :name="el.name"
                  :val="el.info.val"
                  :vals="el.vals"
                  :offset="el.info.offset"
                  :inverse="el.inverse"/>
              </template>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="slide-form">
      <ModalRight v-show="flagModalRight"
        @close="flagModalRight = false;"
        class="w100"
        :title="modalTitle">
        <ListManage v-if="flagModalRight"
          :action="modalComponent.action"
          :outerFilter="modalComponent.config" />
      </ModalRight>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import Chotometr from '@/components/parts/Chotometr.vue';
import LifeColumn from '@/components/parts/LifeColumn.vue';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import ListManage from '@/components/v2/cross/list/ListManage.vue';
import Loader from '@/components/v2/cross/loader/Loader.vue';

export default {
  name: 'IfaceDashboard',
  components: {
    Chotometr, LifeColumn, Filter, ModalRight, ListManage, Loader,
  },
  data() {
    return {
      filter: {},
      loader: false,
      loaderGlobal: true,
      flagModalRight: false,
      modalTitle: '',
      modalComponent: {
        action: '/v2/product/list',
        config: {},
      },
      action: '/v2/dashboard/iface',

      diagramms: [],
      diagrmmms2: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loaderGlobal = true;
      axios.get(this.action)
        .then((res) => {
          if (res.data.success) {
            this.filter = res.data.filter;
            this.diagramms = res.data.diagramms;
            this.diagramms2 = res.data.diagramms2;
            this.loaderGlobal = false;
          } else {
            alert(res.data.answer);
            this.loaderGlobal = false;
          }
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    showGraph(options) {
      this.loader = true;
      setTimeout(() => {
        axios.post(this.action, { filter: options })
          .then((res) => {
            this.filter = res.data.filter;
            this.diagramms = res.data.diagramms;
            this.diagramms2 = res.data.diagramms2;
            this.loader = false;
          })
          .catch(() => {
            this.$router.push('/404');
            this.loader = false;
          });
      }, 50);
    },
    toggleGraph(data) {
      this.graphOptions.legend[data.ind].show = data.val;
    },
    showSector(data) {
      this.modalComponent.config = {};
      console.log(data.sector);
      this.modalTitle = `${data.lbl} "${data.sector.label}" ${Math.round(data.sector.percent)}% [${data.sector.cnt}]`;
      document.body.style.overflowY = 'hidden';

      let stock = 0;
      if (this.filter.left && this.filter.left.length > 1) {
        stock = this.filter.left[1].value.val;
      } else {
        stock = this.filter.left[0].value.val;
      }
      this.modalComponent.config.stock = stock;
      this.modalComponent.config.typeGroup = data.name;
      this.modalComponent.config.typeGroup2 = data.sector.type;

      this.flagModalRight = true;
    },
  },
};
</script>

<style scoped>
.square{
  width: 17px;
  height:17px;
  display: inline-block;
  margin-right: 6px;
}

.diag-title{
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.c-3{
  text-align: center;
  padding-bottom:30px;
  margin-bottom: 0px;
}
.r .c-3:empty{
  display: none;
}
.r.odd{
  backgrounf: #eee;
}
.list{
  text-align: left;
  overflow: hidden;
  display: inline-block;
  margin-top: 20px;
}
.list .el{
  font-size: 22px;
  line-height: 26px;
}

.list-empty{
  text-align: left;
  overflow: hidden;
  display: inline-block;
  margin-top: 20px;
}
.list-empty .el{
  width: 200px;
  height: 22px;
  background: #ccc;
  margin-bottom: 6px;
}

@media (max-width: 992px) {
  .c-3{
    width: 50%;
  }
}
@media (max-width: 768px) {
  .c-3{
    width: 100%;
  }
}
@media (max-width: 576px) {
  .c-3{
    width: 100%;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}

.slide-form-leave-active {
  transition: width 0.1s ease-in;
}
.slide-form-enter-from, .slide-form-leave-to{
  width: 200%;
}
.slide-form-enter-to, .slide-form-leave-from{
  width: 100%;
}
</style>
