<template>
  <div class="wb-input-text"  style="display: flex">
    <div style="position:relative">
      <div class="label" v-if="fields.lbl">{{fields.lbl}}</div>
      <label :for="h">
        <input
          :id="h"
          :class="{'error-input': err, 'req': req}"
          type="number"
          :placeholder="def"
          :value="hour"
          min="hMin"
          max="hMax"
          autocomplete="off"
          @input="onInputHour"
          @focus="onFocus" />
      </label>
      <div class="tField">{{hTitle}}</div>
    </div>
    <div class="separator">:</div>
    <div style="position:relative">
      <label :for="m">
        <input
          :id="m"
          :class="{'error-input': err, 'req': req}"
          type="number"
          :placeholder="def"
          :value="minute"
          min="mMin"
          max="mMax"
          autocomplete="off"
          @input="onInputMinute"
          @focus="onFocus" />
      </label>
      <div class="tField">{{mTitle}}</div>
    </div>
    <transition>
      <span class="error" v-if="err">
        <i class="close" @click="onClose" @keydown.x="onClose">&#10006;</i>
        {{err}}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: ['fields', 'value', 'hour', 'minute'],
  emits: ['update:hour', 'update:minute', 'outError'],
  data() {
    return {
      show_ext_lbl: false,
      id: null,

      mMin: 0,
      mMax: 59,
      hMin: 0,
      hMax: 23,
      hTitle: 'Часы',
      mTitle: 'Минуты',
    };
  },
  mounted() {
    this.id = this.$.uid;
  },
  methods: {
    onInputHour(event) {
      if (event.target.value.length !== 0) {
        this.$emit('outError', this.ind);
      }
      if (event.target.value > this.hMax) {
        event.target.value = this.hMax;
      }
      if (event.target.value < this.hMin) {
        event.target.value = this.hMin;
      }
      this.$emit('update:hour', event.target.value);
    },
    onInputMinute(event) {
      if (event.target.value.length !== 0) {
        this.$emit('outError', this.ind);
      }
      if (event.target.value > this.mMax) {
        event.target.value = this.mMax;
      }
      if (event.target.value < this.mMin) {
        event.target.value = this.mMin;
      }
      this.$emit('update:minute', event.target.value);
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
  },
};
</script>

<style scoped>
  .wb-input-text{
    position: relative;
    margin-left: 0px;
  }
  input {
    display: inline-block;
    width: 100%;
    padding: 10px 12px;
    border-radius: 0 4px 4px 0px;
    border: 1px solid #aaa;
    border-left: 4px solid #aaa;
    font-size: 16px;
    line-height: 18px;
    outline: none;
    box-sizing: border-box;
  }
  input:hover{background:#efefef}
  .ext-label{
    position: absolute;
    top: -10px;
    left: 8px;
    display: block;
    border: 1px solid #aaa;
    padding: 0px 6px;
    font-size: 12px;
    background: #efefef;
  }
  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }
  .req{border-left: 4px solid #2567f3;}
  .error{
    float: right;
    position: absolute;
    display: block;
    top: 36px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    background: #f3c9c9;
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
    padding: 3px 11px 2px;
    color: #b33d3d;
  }
  .error-input{
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
  }
  .error-input::-webkit-input-placeholder {color: #e57272;}
  .error-input:-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input::-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input:-ms-input-placeholder {color: #e57272;}
  .error-input::-ms-input-placeholder {color: #e57272;}
  .error-input::placeholder {color: #e57272;}
  .error .close{
    float: right;
    cursor: pointer;
    font-style: normal;
    margin-right: -11px;
    margin-top: 0px;
    display: block;
    width: 18px;
    height: 18px;
    text-align: center;
  }
  .separator{
    font-size: 20px;
    font-weight: bold;
    margin: 0px 10px;
    line-height: 40px;
  }
  .tField{
    position: absolute;
    right: 0px;
    bottom: -12px;
    font-size: 12px;
    line-height: 14px;
    background: #e7e7e7;
    padding: 2px 4px;
    border: 1px solid #aaa;
  }
</style>
