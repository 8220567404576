<template>
  <div class="iface-chg">
    <div class="wrp-block">
      <div v-for="(el, ind) in listCompany" :key="ind"
        class="el"
        :class="{selected: el.fields[0].val == currCompany}"
        @click="getStock(el.fields[0].val)"
        @keydown.f7="getStock(el.fields[0].val)">
        {{el.fields[1].val}}
      </div>
    </div>
    <br />
    <div class="wrp-block">
      <div v-for="(el, ind) in listStock" :key="ind"
        class="el"
        :class="{selected: el.val == currStock}"
        @click="getProd(el.val, 0)"
        @keydown.f7="getProd(el.val, 0)">
        {{el.lbl}}
      </div>
      <!--<pre>{{listStock}}</pre>-->
    </div>
    <div class="wrp-category">
      <SelectIerarch v-if="category.flag == true"
        v-model="category.value"
        :lbl="category.lbl"
        :def="category.placeholder"
        :action="category.action"
        :key="category.flag"
        @change="chgCategory" />
    </div>
    <transition name="fade" class="wrp-block">
      <div v-if="!loaderProd">
        <div v-if="extInfoList.cntrange" class="block-color">
          <div class="elem" v-if="cntElem > 0">{{cntElem}}</div>
          <div v-for="(el, ind) in extInfoList.cntrange" :key="ind" class="elem"
            :class="el.lbl">{{el.val}} ({{ Math.round((el.val / cntElem) * 100)}}%)</div>
        </div>
        <template v-if="headSetkaProducts.length == 0">
          <div v-for="(el, ind) in listElem" :key="ind"
            class="elem"
            :class="el.buffer.color"
            @click="getGraph(el.val.val)"
            @keydown.f7="getGraph(el.val.val)">
              <div class="" style="float:right">{{el.cnt.val}}</div>
              <span class="buffer" :class="el.buffer.color"> {{el.buffer.val}}% </span>
              {{el.name.val}} {{el.articul.val}}
          </div>
        </template>
        <template v-else>
          <div>
            <table class="setka-sizes">
            <thead>
              <th class="block-head">Наименование</th>
              <th v-for="(el, ind) in headSetkaProducts" :key="ind"
                class="block-head">{{el}}</th>
            </thead>
            <tbody>
              <tr v-for="(el, ind) in listSetkaProducts" :key="ind">
                <td class="block-head-line">
                  {{el.name}}
                </td>
                <td v-for="(head, ind2) in headSetkaProducts" :key="ind2">
                  <div v-if="el.sizes[head]"
                    class="block-cell no-empty"
                    :class="el.sizes[head].buff.color"
                    @click="getGraph(el.sizes[head].param.prod)"
                    @keydown.f7="getGraph(el.sizes[head].param.prod)"
                    >
                    {{el.sizes[head].buff.val}}%
                  </div>
                  <div v-else class="block-cell block-empty"></div>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
        </template>
      </div>
      <LoaderTreeSpin v-else />
    </transition>
    <transition name="fade">
      <div v-if="showArticle" class="context-menu modal-form"
        @click="closeModalArticle"
        @keydown.f7="closeModalArticle">
        <div class="context-menu--body"
          style="padding:0px !important; content:inherit"  @click.stop >
          <div class="head-graph" v-if="article.info.list">
            <div class="line-1">
              <strong class="stock-head">{{article.info.list[0][0]}}: </strong>
              <span class="stock-name">{{article.info.list[0][1]}}</span>&nbsp;
              <strong class="category-head">{{article.info.list[1][0]}}: </strong>
              <span class="category-name">{{article.info.list[1][1]}}</span>&nbsp;
              <span>{{this.currStock}}:{{this.currProduct}}</span>
            </div>
            <div class="btn-close-big"
              @click.prevent="closeModalArticle"
              @keydown.f7.prevent="closeModalArticle">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="line-1">
              <span>[{{article.info.list[2][1]}}] </span>
              <span class="prod-name">{{article.info.list[3][1]}}</span>
              <a href="javascript:void(0)" class="btn-set-prod" @click="showFormChgCu">
                (<i class="fa fa-cog" aria-hidden="true"></i>)
              </a>
            </div>
          </div>
          <div class="right-top-icons">
            <div v-for="(el, ind) in article.info3" :key="ind"
              :title="el.title"
              class="flag-line"
              :class="el.color">
              <div v-if="el.type == 0" class="icon-head">
                <i :class="el.icon" aria-hidden="true"></i>
              </div>
              <div v-else class="icon-head">{{el.icon}}</div>
              <div class="icon-body">{{el.val}}</div>
            </div>
          </div>
          <div class="wrp--filter" v-if="filter[0]">
            <div class="filter--block" >
              <InputCalendar v-model="filter[0].value"
                :lbl="filter[0].placeholder"
                :type="filter[0].align"
                :months="filter[0].months"
                :week-days="filter[0].weekDays"
                @update:modelValue="reculcGraph" />
              <a class="filter--btn" href="javascript:void(0)"
                @click="chgCus">Переписать ЦУ</a>
            </div>
            <div class="filter--block">
              <div style="width: 250px">
              <SelectCustom v-model="filter[1].value"
                :def="filter[1].placeholder"
                :lbl="filter[1].lbl"
                :options="filter[1].options"
                :err="filter[1].error"
                :req="filter[1].required"
                :ind="index" />
              </div>
              <a class="filter--btn" href="javascript:void(0)"
                @click="recoverFromArch">Восстановить выбранный архив</a>
            </div>
          </div>
          <div class="wrp-graph"
            v-show="article.graphData.length">
            <WBChart
              :dataGraph = "article.graphData"
              :options = "article.graphOptions"
              @chg-options = "toggleGraph"/>
          </div>
          <div class="wrp-text-warn-1" v-if="article.graphData.length == 0">
            <div class="text-warn-1">
              {{article.answer}}
            </div>
          </div>
          <div class="wrp-table" v-if="article.info.title">
            <div class="title">{{article.info.title}}</div>
            <table>
              <tr v-for="(elem, ind) in article.info.list" :key="ind">
                <template v-if="ind > 3">
                  <td v-for="(el, ind2) in elem" :key="ind2">{{el}}</td>
                </template>
              </tr>
            </table>
          </div>

          <div class="wrp-table" v-if="article.info4.list && article.info4.list.length">
            <div class="title" v-if="article.info4.title">{{article.info4.title}}</div>
            <table>
              <tr v-for="(el, ind) in article.info4.list" :key="ind">
                <td>[{{el.articul}}] {{el.name}}</td>
              </tr>
            </table>
          </div>
          <div class="wrp-table" v-if="article.info2.head">
            <div class="title" v-if="article.info2.title">{{article.info2.title}}</div>
            <table>
              <tr class="table-title">
                <th v-for="(elem, ind) in article.info2.head" :key="ind">{{elem}}</th>
              </tr>
              <tr v-for="(elem, ind) in article.info2.list" :key="ind">
                <td v-for="(ele, ind2) in elem" :key="ind2">{{ele}}</td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="flagShowFormChgProd" class="context-menu right modal-form"
        @click="closeFormChgCu"
        @keydown.f7="closeFormChgCu">
        <div class="context-menu--body right"
          :class="{ready: flagShowFormChgProd}"
          @click.stop >
          <div class="title">Настройка параметров товара:<br />
            <strong>{{article.info.list[0][0]}}: </strong>
            <span>{{article.info.list[0][1]}}</span><br />
            <strong>Товар: </strong>
            <span>{{article.info.list[3][1]}}</span>
          </div>
          <div class="field">
            <label for="cu2">
              <div class="field-title">Новый ЦУ</div>
              <input id="cu2" type="text" v-model="newCuForFirstArchive" />
              <a href="javascript:void(0)"
                class="btn-form-green"
                @click="saveChgCu">Изменить ЦУ за {{currDate}}</a>
            </label>
          </div>
          <div class="field">
            <label for="rf2">
              <div class="field-title">Новое время пополнения для поставщика</div>
              <input id="rf2" type="text" v-model="newRefillTime" />
              <a href="javascript:void(0)"
              class="btn-form-green"
                @click="saveNewRefillTime">Изменить время пополнения</a>
                <div class="hint-descr" v-html="currSupplier"></div>
            </label>
          </div>
          <div class="block-answer" v-if="answerForFirstArchive.success">
          <strong>Ответ запроса:</strong> {{answerForFirstArchive.answer}}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import WBChart from '@/components/parts/WBChart.vue';
import InputCalendar from '@/components/atoms/InputCalendar.vue';
import SelectCustom from '@/components/atoms/Select.vue';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';
import SelectIerarch from '@/components/atoms/SelectIerarch.vue';

export default {
  name: 'ChgCu',
  components: {
    WBChart, InputCalendar, SelectCustom, LoaderTreeSpin, SelectIerarch,
  },
  data() {
    return {
      listCompany: [],
      listStock: [],
      listElem: [],
      cntElem: 0,
      extInfoList: [],

      currCompany: 0,
      currStock: 0,
      currProduct: 0,

      errors: [],

      showArticle: false,
      article: {
        graphData: [],
        graphOptions: [],
        info: {
          title: '',
        },
        info2: {
          title: '',
        },
        info3: [],
        info4: [],
        linkUrl: '',
        linkParam: '',
        configLink: '',
        answer: '',
      },
      filter: [],

      loaderProd: false,

      flagShowFormChgProd: false,
      newCuForFirstArchive: 2,
      newRefillTime: 1,
      answerForFirstArchive: {},
      suppMainID: 0,
      extInfo: {},

      category: {
        value: 0,
        lbl: 'Категория',
        placeholder: '-- Выберите категорию --',
        actionDef: '/scm/category/ierarch-plain?stock=',
        action: '/scm/category/ierarch-plain?stock=',
        flag: false,
      },

      listSetkaProducts: [],
      headSetkaProducts: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    currDate() {
      let str = '';
      if (this.filter[0].value) {
        const date = new Date(this.filter[0].value * 1000);
        str = `${date.getDate()} ${this.filter[0].months[date.getMonth()]} ${date.getFullYear()}`;
      } else {
        str = '00.00.0000';
      }
      return str;
    },
    currSupplier() {
      let res = '';
      if (this.article.info2.list[0]) {
        const supp = this.article.info2.list[0];
        res = `Поставщик: <strong>${supp[0]}</strong>, Время пополнения: <strong>${supp[1]}</strong>,  Минимальная партия: <strong>${supp[2]}</strong>`;
      }
      return res;
    },
  },
  methods: {
    init() {
      axios.get('/god/company/lst')
        .then((res) => {
          this.listCompany = res.data.pack.items;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getStock(val) {
      if (this.currCompany === val) return;
      this.currCompany = val;
      this.category.flag = false;
      axios.get('/god/all/list-stock', { params: { val } })
        .then((res) => {
          this.listStock = res.data;
          this.listElem = [];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getProd(val, cat) {
      // if (this.currStock === val) return;
      this.currStock = val;
      this.loaderProd = true;
      this.category.flag = false;
      this.category.action = `${this.category.actionDef}${val}&comp=${this.currCompany}`;

      axios.get('/god/all/list-product', { params: { val: this.currCompany, val2: val, cat } })
        .then((res) => {
          this.listElem = res.data.list;

          this.listSetkaProducts = res.data.ll2;
          this.headSetkaProducts = res.data.lh2;

          this.extInfoList = res.data.extInfo;
          this.cntElem = this.listElem.length;
          this.loaderProd = false;
          this.category.flag = true;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getGraph(val) {
      this.currProduct = val;
      this.showArticle = true;
      document.body.style.overflowY = 'hidden';
      document.body.style.overflowX = 'hidden';

      const filter = {
        left: [
          {
            value: {
              val: this.currStock,
            },
          }, {
            value: val,
          },
        ],
        right: [
          {
            value: 0,
          },
        ],
      };

      this.getInfoGraph(filter);
    },
    reculcGraph() {
      const filter = {
        left: [
          {
            value: {
              val: this.currStock,
            },
          }, {
            value: this.currProduct,
          },
        ],
        right: [
          {
            value: this.filter[0].value,
          },
        ],
      };

      this.getInfoGraph(filter);
    },
    getInfoGraph(filter) {
      console.log('ddd');
      axios.get('/god/all/chg-cu-filter', {
        params: {
          compID: this.currCompany,
          stockID: this.currStock,
          prodID: this.currProduct,
        },
      })
        .then((res) => {
          this.filter = res.data.filter;

          axios.post('/scm/api/report/articul', { filter, compID: this.currCompany, modeorigcu: 1 })
            .then((res) => {
              this.article.graphData = res.data.graphData;
              this.article.graphOptions = res.data.graphOptions;
              this.article.answer = res.data.answer;

              this.article.info = res.data.info;
              this.article.info2 = res.data.info2;
              this.article.info3 = res.data.info3;
              this.article.info4 = res.data.info4;

              this.article.configLink = res.data.configLink;

              this.suppMainID = res.data.suppMainID;
              this.extInfo = res.data.extInfo;
              this.filter[0].value = res.data.from;
            })
            .catch(() => {
            });
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    chgCus() {
      axios.post('/god/all/apply-new-cu', {
        stockID: this.currStock,
        prodID: this.currProduct,
        compID: this.currCompany,
        extInfo: this.extInfo,
        cus: this.article.graphData[0],
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.reculcGraph();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    recoverFromArch() {
      axios.post('/god/all/recover-from-arch', {
        compID: this.currCompany,
        stockID: this.currStock,
        prodID: this.currProduct,
        val: this.filter[1].value,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.reculcGraph();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showFormChgCu() {
      this.flagShowFormChgProd = true;
    },
    closeFormChgCu() {
      this.flagShowFormChgProd = false;
    },
    saveChgCu() {
      axios.post('/god/all/safe-first-chg-cu', {
        stockID: this.currStock,
        prodID: this.currProduct,
        compID: this.currCompany,
        needseconds: this.filter[0].value,
        cuFirst: this.newCuForFirstArchive,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answerForFirstArchive = res.data;
            this.reculcGraph();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    saveNewRefillTime() {
      axios.post('/god/all/safe-main-rt', {
        stockID: this.currStock,
        prodID: this.currProduct,
        compID: this.currCompany,
        suppID: this.suppMainID,
        val: this.newRefillTime,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answerForFirstArchive = res.data;
            this.reculcGraph();
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    closeModalArticle() {
      document.body.style.overflow = 'visible';
      document.body.style.overflowX = 'visible';
      this.showArticle = false;
    },
    chgCategory() {
      this.getProd(this.currStock, this.category.value.val);
    },
    toggleGraph(data) {
      this.article.graphOptions.legend[data.ind].show = data.val;
    },
  },
};
</script>

<style scoped>
.block-color{
  margin-top: -31px;
  overflow: hidden;
}
.block-color .elem{
  float: right;
}
.block-answer{
  background-color: #a8c2e7;
  padding: 8px;
}
input[type="text"]{
  height: 40px;
  box-sizing: border-box;
  padding: 8px;
  width: 74px;
  vertical-align: top;
}
.hint-descr{
  margin-top: 5px;
}
.btn-form-green{
  margin-left: 12px;
  background: #71a460;
  padding: 10px 16px 10px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
}
.field-title{
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 8px;
}
.btn-set-prod{
  color: #0000ee;
}
.filter--block{
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
}
.filter--block > div, .filter--block > a{
  display: inline-block;
  vertical-align: top;
}
.filter--btn {
  display: inline-block;
  padding: 11px 12px;
  background-color: #60af80;
  color: #fff;
  transition: .8s;
}
.el{
  padding: 8px 6px;
  display: inline-block;
  vertical-align: top;
  background-color: #ddd;
  margin-right: 4px;
  cursor: pointer;
  transition: background-color 0.7s;
}
.el.selected{
  background-color: #dbc3ff;
}

.elem{
  padding: 8px 6px;
  background-color: #ddd;
  margin-right: 4px;
  cursor: pointer;
  transition: background-color 0.7s;
  margin-top: 2px;
  transition: background-color 0.5s;
}
.elem:hover{
  background-color: #ccc;
}

.head-graph{
  padding-bottom: 10px;
  /* background: white; */
  position: relative;
}
.line-1{
  background: #d2d2d2;
  padding: 3px 10px;
}
.line-1:first-child{
  padding-top: 10px;
}
.line-1:last-child{
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 24px;
  color: #cb2222;
}
.inline-block{
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
}
.inline-block .title{
  font-weight: bold;
  margin-bottom: 10px;
}
.inline-block table{
  border-collapse: collapse;
}
.inline-block table th{
  background: transparent;
  padding: 4px;
}
.inline-block tr td{
  padding: 4px;
  border-bottom: 1px solid #aaa;
}
.inline-block tr:empty{
  display: none;
}
.btn-m{
  font-size: 22px; line-height: 22px;
  display:inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #556b7d;
}

.big-icon{
  line-height: 32px;
  font-size: 18px;
}
.wrp-icon-filter{
  width: 100%;
  text-align: center;
  background: #e6e6e6;
}

.btn-close-big{
  position: absolute;
  right: 20px;
  top: 4px;
  font-size: 30px;
  line-height: 30px;
  color: #780c0c;
  cursor: pointer;
  transition: color 0.7s;
}
.btn-close-big:hover {
  color: #bf1111;
}

.right-top-icons{
  margin-top: -10px;
  z-index: 1;
  position: relative;
  background: #bbb;
  float: right;
}
.flag-line{
  display: inline-block;
  vertical-align: top;
}
.right-top-icons .flag-line{
  margin: 0px 0px 0px 2px;
  background: #efefef;
  padding: 4px 8px 2px;
  text-align: center;
  min-width: 30px;
  cursor: default;
}
.right-top-icons .flag-line > div {
  display: inline-block;
}

.wrp-graph{
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
}

.flag-line.blue,
.flag-line.green,
.flag-line.yellow,
.flag-line.red,
.flag-line.black,
.flag-line.gray{
  background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,
                    transparent 0,
                    transparent 50%,
                    hsla(0,0%,100%,.15) 0,
                    hsla(0,0%,100%,.15) 75%,
                    transparent 0,
                    transparent);
  color: #fff;
}
.flag-line.blue{ background-color: #5baade;}
.flag-line.green { background-color: #5cb85c;}
.flag-line.yellow { background-color: #f0ad4e; color: #000;}
.flag-line.red { background-color: #d9534f;}
.flag-line.black { background-color: #757575;}

.flag-line.new{ background: #e7e992; color: #136e23; }

.elem.blue,
.elem.green,
.elem.yellow,
.elem.red,
.elem.black,
.elem.gray{
  background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,
                    transparent 0,
                    transparent 50%,
                    hsla(0,0%,100%,.15) 0,
                    hsla(0,0%,100%,.15) 75%,
                    transparent 0,
                    transparent);
  color: #fff;
}
.elem.blue{ background-color: #5baade;}
.elem.blue:hover{ background-color: #009aff;}
.elem.green { background-color: #73b873;}
.elem.green:hover { background-color: #007a00;}
.elem.yellow { background-color: #e7df63; color: #000;}
.elem.yellow:hover { background-color: #e5d700;}
.elem.red { background-color: #de605c;}
.elem.red:hover { background-color: #cf0600;}
.elem.black { background-color: #757575;}
.elem.black:hover { background-color: #2a2a2a;}

.elem.new{ background: #e7e992; color: #136e23; }

.wrp-table {
  text-align: left;
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  background-color: #fff;
  margin-top: 12px;
}
.wrp-table .title {
  font-weight: 700;
  font-size: 18px;
  padding-left: 8px;
  padding-top: 8px;
  margin-bottom: 8px;
}
.wrp-table table td, .wrp-table table th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.context-menu--body.ready.right {
  width: 50%;
  height: 100%;
}
.context-menu--body.right {
  width: 0;
  height: 100%;
  overflow: auto;
  padding: 10px;
  transition: width 1s;
}
.context-menu.right{
  text-align: right;
}

.modal-form .field{
  margin-bottom: 12px;
}
.context-menu.right .title{
  ont-size: 20px;
  line-height: 22px;
  margin-bottom: 12px;
}
.wrp-category{
  overflow: hidden;
  width: 250px;
  margin-top: 10px;
  padding-top: 10px;
}

.block-head{
  background: #e6e6e6;
  padding: 6px 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}

table th{
  position: sticky;
  top: 50px;
  background: #fff;
  z-index: 10;
  transition: width 1s;
}

.block-head-line{
    position: sticky;
    left: 0;
    background-color: #fff;
}
table.setka-sizes{
  border-spacing: 0px;
  border-collapse: collapse;
  margin-bottom: 40px;
}
table.setka-sizes tbody td{
  min-width: 65px;
  border-bottom: 1px solid #ccc;
}

.setka-sizes .green{ background: #a8e0a8;}
.setka-sizes .red{ background: #fbb4b2;}
.setka-sizes .blue{ background: #9cd8ff; }
.setka-sizes .yellow { background: #ffdeb0;}
.setka-sizes .black { background: #b0b0b0; }

.block-cell{
  padding: 8px 8px;
  text-align: center;
  font-size: 14px;
}
.block-cell.no-empty{
  cursor: pointer;
  transition: opacity .5s;
}
.block-empty{
  border: 22px solid #e4e4e4; /* $c1c1c1 */
  border-bottom: 12px solid #dadada;
  border-left: 43px solid #dadada;
  width: 0px;
  height: 0px;
  padding: 0px;
}
</style>
