<template>
  <div class="wb-autocomplette" v-click-outside="closeSelect">
<!--    <div class="btn-show-search one-line"
      @click="toggleSearch"
      @keydown.f8="toggleSearch">
      <i class="fa fa-search" aria-hidden="true"></i>
    </div>-->
    <div class="area-select one-line"
      :class="{active: modeInput}"
      >
      <!--   <div v-show="modeInput">
        <input :id="id"
          v-model="search"
          class="text search"
          ref="inputSearch"
          @input="filterList"
          :placeholder="field.def"
          autocomplete="off"/>
        <div v-if="showClear" class="btn-clear" @click.stop="newinput" @keydown.tab.stop="newinput">
          <i class="fa fa-times-circle" aria-hidden="true"></i>
        </div>
      </div> -->
      <div v-show="!modeInput">
        <div class="wrp-select-el">
          {{selectedEl.value.lbl}} <span v-if="field.value.cnt">({{field.value.cnt}})</span>
        </div>
      </div>
    </div>
    <div class="btn-show-list one-line"
      :class="{show: showList}"
      @click="toggleList"
      @keydown.f7="toggleList">
      <i class="fa fa-angle-down" aria-hidden="true"></i>
      <i class="fa fa-angle-up" aria-hidden="true"></i>
    </div>
    <div class="wb-autocomplette-list" v-if="showList">
      <template v-if="autoList.length">
        <div v-for="(el, index) in autoList" :key="index">
          <div v-if="el.flag_main === 0" class="list--item"
            :class="{active: el.flag}"
            @click="selectProduct(index)"
            @keydown.enter="selectProduct(index)">
              {{el.lbl}} <span v-if="el.cnt">({{el.cnt}})</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="list--item">Не найдено</div>
      </template>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'SuperSelect',
  props: [
    'field',
  ],
  data() {
    return {
      showList: false,
      search: '',
      loader: false,
      modeInput: false,
      showClear: false,
      selectedEl: this.field,

      autoList: this.field.options,
      id: null,
    };
  },
  mounted() {
    this.id = `auto${this.field.value.val}`;
    this.visibleAll();
  },
  methods: {
    filterList() {
      this.showList = true;
      this.visiblePart(this.search);
    },
    toggleList() {
      if (this.showList) {
        this.showList = false;
      } else {
        this.showList = true;
      }
    },
    selectProduct(ind) {
      this.unselect();
      this.showList = false;
      // this.search = lbl;
      this.autoList[ind].flag = 1;

      this.selectedEl.value = this.autoList[ind];

      this.$emit('select', this.autoList[ind]);
      // this.modeInput = false;
    },
    unselect() {
      let i = this.autoList.length;
      while (i) {
        i -= 1;
        this.autoList[i].flag = 0;
      }
    },
    visibleAll() {
      let i = this.autoList.length;
      while (i) {
        i -= 1;
        this.autoList[i].flag_main = 0;
      }
    },
    visiblePart(search) {
      let i = this.autoList.length;
      while (i) {
        i -= 1;
        const l = this.autoList[i].lbl.toLowerCase();
        const s = search.toLowerCase();

        if (~l.indexOf(s)) { // eslint-disable-line no-bitwise
          this.autoList[i].flag_main = 0;
        } else {
          this.autoList[i].flag_main = 1;
        }
      }
    },
    closeSelect() {
      this.showList = false;
      if (this.modeInput === true) {
        this.modeInput = false;
        this.visibleAll();
      }
    },
    toggleSearch() {
      if (this.modeInput) {
        this.modeInput = false;
      } else {
        this.modeInput = true;
        this.$nextTick(() => this.$refs.inputSearch.focus());
      }
    },
    entermouse() {
      this.modeInput = true;
      if (this.search !== '') {
        this.showClear = true;
      }
    },
    leavemouse() {
      this.showClear = false;
    },
    leavemouseglobal() {
      this.modeInput = false;
      this.visibleAll();
      this.search = '';
      // this.showList = false;
    },
    newinput() {
      this.search = '';
      this.showClear = false;
      this.modeInput = true;
    },
  },
};
</script>

<style scoped>
  .area-select{
    position: relative;
    transition: width 0.5s;
    width: auto;
  }
  /*.area-select.active{
    width: 90%;
  }*/
  .wrp-select-el{
    box-sizing: border-box;
    line-height: 38px;
    border: 1px solid #dadee0;
    border-right: none;
    padding: 0px 12px;
    font-size: 14px;
  }
  .search{
    width: 20%;
    transition: width 1s;
  }
  .area-select.active .search{
    width: 100%;
  }
  .one-line{
    display: inline-block;
    vertical-align: top;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    transition: max-width 0.6s;
  }
  .one-line:hover, .wb-autocomplette-list:hover {
    max-width: 800px;
  }
  .btn-show-search{
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    border: 1px solid #848484;
    border-left-width: 4px;
    font-size: 20px;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    border: 1px solid #aaa;
    border-right: none;
  }
  .btn-show-search i{
    box-sizing: border-box;
    display: block;
    line-height: 40px;
  }
  .btn-show-list{
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    font-size: 20px;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    border: 1px solid #dadee0;
    border-radius: 0px 0px 5px 0px;
/*    border-left: none;*/
  }
  .btn-show-list i{
    box-sizing: border-box;
    display: block;
    line-height: 40px;
  }
  .btn-show-list i:first-child{
    margin-top: 0px;
    transition: margin-top 0.5s;
  }
  .btn-show-list.show i:first-child{
    margin-top:-42px;
  }

  .wb-autocomplette{
    position: relative;
  }
  .wb-autocomplette input.text{
    line-height: 18px;
    border: 1px solid #aaa;
    padding: 10px 12px;
    border-radius: 0px 0px 0px 0px;
    border-left-width: 4px;
    outline:none;
    box-sizing: border-box;
    font-size: 16px;
  }
  .wb-autocomplette input.text:hover{
    background: #efefef;
  }
  .wb-autocomplette-list{
    position: absolute;
    left: 0px;
    top: 39px;
    border:1px solid #dadee0;
/*    border-left-width: 4px;*/
    max-height: 340px;
    overflow-y: auto;
    background: #fff;
    font-size: 14px;
    z-index: 12;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    transition: max-width 0.6s;
/*    margin-left: 40px;*/
  }
  .wb-autocomplette-list .list--item {
    padding:8px 12px;
    cursor: pointer;
    transition: background-color 0.5s;
  }
  .wb-autocomplette-list .list--item.active{
    cursor: default;
    background-color: #14c8ff;
    color: #fff;
  }
  .list--item:hover {
    background-color: #ddd;
  }
  .wb-autocomplette-list .list--item:hover.active {
    background-color: #14c8ff;
  }
  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }
  .btn-clear{
    position: absolute;
    top: 50%;
    margin-top: -12px;
    right: 6px;
    cursor: pointer;
    font-size: 24px;
    z-index: 15;
    background: #fff;
    border-radius: 24px;
    line-height: 23px;
    color: #777;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
