import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import store from '@/store/index';
// import Frontpage from '@/views/Frontpage.vue';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Settings from '@/views/admin/Settings.vue';
import Rp from '@/views/rp/Panel.vue';
import AC from '@/views/admin/AC.vue';
import DB from '@/views/admin/DB.vue';

import IfaceStockSet from '@/views/v2/IfaceStockSet.vue';
import IfaceCompanySet from '@/views/v2/IfaceCompanySet.vue';

import ManagerTask from '@/views/scm/task/ManagerTask.vue';
import ManagerControll from '@/views/scm/task/ManagerControll.vue';
// import Scm from '@/views/scm/Panel.vue';
import Dashboard from '@/views/v2/IfaceDashboard.vue';
import PanelShop from '@/views/scm/PanelShop.vue';
import ScmProduct from '@/views/scm/IfaceProduct.vue';
import ScmAkcii from '@/views/scm/IfaceAkcii.vue';

import ScmModeling from '@/views/scm/modeling/IfaceModeling.vue';
import ModelingAddStepOne from '@/views/scm/modeling/addStepOne.vue';
import ModelingAddStepTwo from '@/views/scm/modeling/addStepTwo.vue';
import ModelingDetail from '@/views/scm/modeling/detail.vue';

import ScmProductUpd from '@/views/scm/IfaceProductUpd.vue';
import ScmProductGroupManage from '@/views/scm/IfaceProductGroupManage.vue';
import ScmProductSet from '@/views/scm/IfaceProductSet.vue';
import ScmCu from '@/views/scm/IfaceCu.vue';
import ScmOrder from '@/views/scm/IfaceOrder.vue';
import ScmAutoorder from '@/views/scm/IfaceAutoorder.vue';
import ScmAutoorderAdd from '@/views/scm/IfaceAutoorderAdd.vue';
import ScmSetting from '@/views/scm/settings/Panel.vue';
import ScmSettingStock from '@/views/scm/settings/Stock.vue';
import ScmSettingStockCustom from '@/views/scm/settings/StockCustom.vue';
import ScmSettingSupp from '@/views/scm/settings/Supp.vue';
import ScmSettingList from '@/views/scm/settings/List.vue';
import ScmSettingProd from '@/views/scm/settings/Prod.vue';
import ScmSettingStockLink from '@/views/scm/settings/LinkCatStock.vue';
import ScmSettingAutoorder from '@/views/scm/settings/Autoorder.vue';
import ScmLogist from '@/views/scm/logist/Panel.vue';
import ScmLogistPark from '@/views/scm/logist/Park.vue';

import Team from '@/views/team/Panel.vue';
import Fin from '@/views/fin/Panel.vue';

import GodDB from '@/views/god/DB.vue';
import GodReq from '@/views/god/GodReq.vue';
import MCompany from '@/views/god/Mcompany.vue';
import MPolitika from '@/views/god/Mpolitika.vue';
import MTask from '@/views/god/Mtask.vue';
import GodAll from '@/views/god/All.vue';
import CronList2 from '@/views/god/CronList.vue';

import TestList from '@/views/god/TestList.vue';
import Test from '@/views/god/Test.vue';
import ChgCu from '@/views/god/ChgCu.vue';
import NormTime from '@/views/god/NormTime.vue';

import GenReport from '@/views/scm/report/Panel.vue';
import Articul from '@/views/scm/report/Articul.vue';
import StatusBuffer from '@/views/scm/report/StatusBuffer.vue';
import ChangeCu from '@/views/scm/report/ChangeCu.vue';
import Output from '@/views/scm/report/Output.vue';
import Surpluses from '@/views/scm/report/Surpluses.vue';
import ReceiveSales from '@/views/scm/report/ReceiveSales.vue';
import RRoi from '@/views/scm/report/Roi.vue';
import RubDS from '@/views/scm/report/RubDayStore.vue';
import RubDP from '@/views/scm/report/RubDayPass.vue';
import Deficit from '@/views/scm/report/Deficit.vue';
import InvestInGoods from '@/views/scm/report/InvestInGoods.vue';
import SupplierReliability from '@/views/scm/report/SupplierReliability.vue';
import ReportInWayNow from '@/views/scm/report/ReportInWayNow.vue';
import ReportInWayEveryDay from '@/views/scm/report/ReportInWayEveryDay.vue';
import LogsDataExchange from '@/views/scm/report/LogsDataExchange.vue';
import ShowCnt from '@/views/scm/report/ShowCnt.vue';

import UserGuide from '@/views/scm/help/UserGuide.vue';
import ArchiveSale from '@/views/scm/archive/ArchiveSale.vue';

import NotFound from '@/components/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'frontpage',
    component: Dashboard,
  }, {
    path: '/v2',
    name: 'dashboard',
    component: Dashboard,
  }, {
    path: '/login',
    name: 'Login',
    component: Login,
  }, {
    path: '/rp',
    name: 'Rp',
    component: Rp,
  }, {
    path: '/scm/task',
    name: 'ManagerTask',
    component: ManagerTask,
  }, {
    path: '/scm/task-controll',
    name: 'ManagerControll',
    component: ManagerControll,
  }, {
    path: '/scm',
    name: 'Scm',
    component: Dashboard,
  }, {
    path: '/scm/shop',
    name: 'PanelShop',
    component: PanelShop,
  }, {
    path: '/scm/logist',
    name: 'ScmLogist',
    component: ScmLogist,
  }, {
    path: '/scm/logist/park',
    name: 'ScmLogistPark',
    component: ScmLogistPark,
  }, {
    path: '/scm/modeling',
    name: 'ScmModeling',
    component: ScmModeling,
  }, {
    path: '/scm/modeling/add-step-one',
    name: 'ModelingAddStepOne',
    component: ModelingAddStepOne,
  }, {
    path: '/scm/modeling/add-step-two',
    name: 'ModelingAddStepTwo',
    component: ModelingAddStepTwo,
  }, {
    path: '/scm/modeling/:id',
    name: 'ModelingDetail',
    component: ModelingDetail,
  }, {
    path: '/scm/product',
    name: 'ScmProduct',
    component: ScmProduct,
  }, {
    path: '/scm/product/upd/:id',
    name: 'ScmProductUpd',
    component: ScmProductUpd,
  }, {
    path: '/scm/product/set',
    name: 'ScmProductSet',
    component: ScmProductSet,
  }, {
    path: '/v2/stock/set',
    name: 'IfaceStockSet',
    component: IfaceStockSet,
  }, {
    path: '/v2/company/set',
    name: 'IfaceCompanySet',
    component: IfaceCompanySet,
  }, {
    path: '/scm/product/group-manage',
    name: 'ScmProductGroupManage',
    component: ScmProductGroupManage,
  }, {
    path: '/scm/cu',
    name: 'ScmCu',
    component: ScmCu,
  }, {
    path: '/scm/order',
    name: 'ScmOrder',
    component: ScmOrder,
  }, {
    path: '/scm/order/autoorder-iface',
    name: 'ScmAutoorder',
    component: ScmAutoorder,
  }, {
    path: '/scm/order/auto-add',
    name: 'ScmAutoorderAdd',
    component: ScmAutoorderAdd,
  }, {
    path: '/scm/setting',
    name: 'ScmSetting',
    component: ScmSetting,
  }, {
    path: '/scm/setting/stock',
    name: 'ScmSettingStock',
    component: ScmSettingStock,
  }, {
    path: '/scm/setting/stock-custom',
    name: 'ScmSettingStockCustom',
    component: ScmSettingStockCustom,
  }, {
    path: '/scm/setting/supp',
    name: 'ScmSettingSupp',
    component: ScmSettingSupp,
  }, {
    path: '/scm/setting/list',
    name: 'ScmSettingList',
    component: ScmSettingList,
  }, {
    path: '/scm/setting/prod',
    name: 'ScmSettingProd',
    component: ScmSettingProd,
  }, {
    path: '/scm/setting/stock-link',
    name: 'ScmSettingStockLink',
    component: ScmSettingStockLink,
  }, {
    path: '/scm/setting/autoorder',
    name: 'ScmSettingAutoorder',
    component: ScmSettingAutoorder,
  }, {
    path: '/team',
    name: 'Team',
    component: Team,
  }, {
    path: '/fin',
    name: 'Fin',
    component: Fin,
  }, {
    path: '/help',
    name: 'Help',
    component: Home,
  }, {
    path: '/admin',
    name: 'DB',
    component: DB,
  }, {
    path: '/admin/ac',
    name: 'AC',
    component: AC,
  }, {
    path: '/admin/settings',
    name: 'Settings',
    component: Settings,
  }, {
    path: '/scm/report',
    name: 'GenReport',
    component: GenReport,
  }, {
    path: '/scm/report/articul',
    name: 'Articul',
    component: Articul,
  }, {
    path: '/scm/report/status-buffer',
    name: 'StatusBuffer',
    component: StatusBuffer,
  }, {
    path: '/scm/report/change-cu',
    name: 'ChangeCu',
    component: ChangeCu,
  }, {
    path: '/scm/report/output',
    name: 'Output',
    component: Output,
  }, {
    path: '/scm/report/surpluses',
    name: 'Surpluses',
    component: Surpluses,
  }, {
    path: '/scm/report/receive-sales',
    name: 'ReceiveSales',
    component: ReceiveSales,
  }, {
    path: '/scm/report/roi',
    name: 'Roi',
    component: RRoi,
  }, {
    path: '/scm/report/rub-day-pass',
    name: 'RubDayPass',
    component: RubDP,
  }, {
    path: '/scm/report/rub-day-store',
    name: 'RubDayStore',
    component: RubDS,
  }, {
    path: '/scm/report/deficit',
    name: 'Deficit',
    component: Deficit,
  }, {
    path: '/scm/report/invest-in-goods',
    name: 'InvestInGoods',
    component: InvestInGoods,
  }, {
    path: '/scm/report/supplier-reliability',
    name: 'SupplierReliability',
    component: SupplierReliability,
    meta: { title: 'Отчет по надежности поставщиков' },
  }, {
    path: '/scm/report/in-way-now',
    name: 'ReportInWayNow',
    component: ReportInWayNow,
  }, {
    path: '/scm/report/in-way-every-day',
    name: 'ReportInWayEveryDay',
    component: ReportInWayEveryDay,
  }, {
    path: '/scm/report/logs-data-exchange',
    name: 'LogsDataExchange',
    component: LogsDataExchange,
  }, {
    path: '/scm/report/show-cnt',
    name: 'ShowCnt',
    component: ShowCnt,
  }, {
    path: '/scm/help/user-guide',
    name: 'UserGuide',
    component: UserGuide,
  }, {
    path: '/scm/archive/sale',
    name: 'ArchiveSale',
    component: ArchiveSale,
  }, {
    path: '/scm/akcii',
    name: 'Akcii',
    component: ScmAkcii,
  }, {
    path: '/god',
    name: 'GodDB',
    component: GodDB,
  }, {
    path: '/god/req',
    name: 'GodReq',
    component: GodReq,
  }, {
    path: '/god/m-company',
    name: 'MCompany',
    component: MCompany,
  }, {
    path: '/god/m-politika',
    name: 'MPolitika',
    component: MPolitika,
  }, {
    path: '/god/task-list',
    name: 'Mtask',
    component: MTask,
  }, {
    path: '/god/all',
    name: 'GodAll',
    component: GodAll,
  }, {
    path: '/god/cron-list',
    name: 'CronList',
    component: CronList2,
  }, {
    path: '/god/test-list',
    name: 'TestList',
    component: TestList,
  }, {
    path: '/god/test',
    name: 'Test',
    component: Test,
  }, {
    path: '/god/chg-cu',
    name: 'ChgCu',
    component: ChgCu,
  }, {
    path: '/god/norm-hours',
    name: 'NormTime',
    component: NormTime,
  }, {
    path: '/404',
    name: '404',
    component: NotFound,
  }, {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user');
  document.body.style.overflow = 'visible';

  if (loggedIn != null) {
    axios.post('/auth/verify', {
      val: loggedIn,
    })
      .then((res) => {
        if (res.data.success === 1) {
          if (res.data.missing === 0 || res.data.missing === 1) {
            store.dispatch('auth/chgMissing', res.data.missing);
          }
          if (to.name !== 'Login' && loggedIn == null) {
            next({ name: 'Login' });
          } else if (to.name === 'Login') {
            next({ name: '' });
          } else {
            next();
          }
        } else {
          localStorage.removeItem('user');
          localStorage.removeItem('menu');
          localStorage.removeItem('qmenu');
          next({ name: 'Login' });
        }
      })
      .catch((e) => {
        this.errors.push(e);
      });
  } else {
    if (to.name !== 'Login') {
      next({ name: 'Login' });
    }

    next();
  }
});

const DEFAULT_TITLE = 'Управление цепочками поставок и запасами';
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
