<template>
  <h1><span>Отчет по обмену данными</span></h1>

  <Filter
    :options = "filter"
    stick = ""
    @change-field="filterLogs" />

  <div class="lists">
    <template v-if="list.length > 0">
      <div v-for="(els, ind) in list" :key="ind" class="el">
        <div class="title"><div class="center">{{els.title}}</div></div>
        <div class="wrp-company" >
          <div v-for="(els2, ind2) in els.els" :key="ind2" class="el2">
            <div class="title2">{{els2.title}}</div>
            <div v-for="(el, ind2) in els2.els" :key="ind2" class="one">
              <div class="inline first" :class="{error: el.ok == 0}">{{el.date}}</div>
              <div class="inline second">{{el.info}}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="text-warn-1">
      Список логов обмена данными пуст
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';

export default {
  name: 'LogsDataExchange',
  components: {
    Filter,
  },
  data() {
    return {
      urlAction: '/scm/api/report/logs-data-exchange',

      list: [],
      filter: [],

      loaderFilter: true,
      loader: true,
      errors: [],
    };
  },
  mounted() {
    if (localStorage.getItem('stock')) {
      this.urlAction += `?stockid=${localStorage.getItem('stock')}`;
    }
    this.init();
  },
  methods: {
    init() {
      axios.get(this.urlAction)
        .then((res) => {
          this.list = res.data.list;
          this.filter = res.data.filter;
        })
        .catch(() => {
          this.loaderFilter = false;
          this.loader = false;
          this.$router.push('/404');
        });
    },
    filterLogs() {
      axios.post(this.urlAction, { filter: this.filter })
        .then((res) => {
          this.list = res.data.list;
        })
        .catch(() => {
          this.loaderFilter = false;
          this.loader = false;
          this.$router.push('/404');
        });
    },
  },
};
</script>

<style scoped>
.report-area-btn{
  float: right;
  margin-right: 12px;
}
.btn-stick-def{
  background: #ddd;
  padding: 4px 10px;
  display: inline-block;
  color: #000;
}
.btn-stick-def.active{
  background: #5981ce;
  color: #fff;
}
.left-round{border-radius: 4px 0 0 4px;}
.right-round{border-radius: 0 4px 4px 0;}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}

.title{
  text-align: center;
  /* background: #e0e6f7; */
  padding-top: 1px;
}
.title .center{
  padding: 4px 10px;
  display: inline-block;
  font-size: 22px;
  line-height: 24px;
  background-color: #7c9ede;
  border-radius: 4px;
  color: #fff;
}
.wrp-company{
  padding: 0px 10px;
}

.inline{
  display: inline-block;
  vertical-align: top;
}
.el2{
  margin-bottom: 12px;
}
.title2{
  font-size: 18px;
  line-height: 20px;
  padding: 6px 0px;
}
.one{
  margin-bottom: 3px;
}
.inline.first{
  background-color: #c3f0db;
  padding: 3px 5px;
  font-size: 16px;
  line-height: 20px;
}
.inline.first.error{
  background-color: #f0c3c3;
}
.inline.second{
  line-height: 20px;
  padding: 3px 0px 3px 6px;
  cursor: default;
}
.inline.second:hover{
  background-color: #ddd;
}

</style>
