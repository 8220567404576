<template>
  <Loader v-if="loader" />
  <div v-else>
    <i
      @click="changeShow"
      @keydown="changeShow"
      class="fa fa-cog" :class="{active: this.outterShowing}"></i>
    <!--<InputTextShortNew class="input-search" />-->
    <div :class="`level-${el.level}`" v-for="(el, ind) in list" :key="ind">
      <div class="item" v-if="el.show"
        :class="{'ierarh-border': el.level > 0}">
        <div v-if="el.level > 0" class="squad-level"></div>
        <span class="el">
          <span style="float:left; display:block;"
            @click="toggleChild(ind)"
            @keydown.f9="toggleChild(0)">
            <template v-if="el.child || dynamic && el.cnt != 0">
              <i v-if="el.open" class="fa fa-folder-open" aria-hidden="true"></i>
              <i v-else class="fa fa-folder" aria-hidden="true"></i>
            </template>
            <span v-else class="empty-squad">
              <i class="fa fa-folder " aria-hidden="true"></i>
            </span>
          </span>
          <Checkbox
            v-model="el.chk"
            @change="changeBlockActive"
            class="category"
            :active="NeedCheckbox"
          />
          <span @click="takeVal(ind)"
            @keydown.f9="takeVal(ind)"
          >
            {{el.lbl + ` (` + el.cnt + `)`}}
          </span>
        </span>
      </div>
    </div>
  </div>
  <div class="block-link" :class="{active: blockActive}">
    <a href="">Применить действия</a>
  </div>
</template>
<script>
import axios from 'axios';
// import ConfirmCrossDef from '@/components/v2/cross/confirm/Def.vue';
// import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import Checkbox from '@/components/v2/serj/Checkbox.vue';
// import InputTextShortNew from '@/new/components/atoms/InputTextShort.vue';
import Loader from '@/components/loader/LoaderTreeSpin.vue';

export default {
  name: 'CategoryFunctional',
  props: {
    stock: {
      type: Number,
      default: 0,
    },
    NeedCheckbox: {
      type: Number,
      default: 1,
    },
    mode: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: '',
    },
    filter: {
      type: Array,
    },
  },
  components: {
    // ConfirmCrossDef,
    // ModalRight,
    Checkbox,
    // InputTextShortNew,
    Loader,
  },
  emits: ['transferCats'],
  data() {
    return {
      outterShowing: 0,
      cats: [],
      list: [],
      loader: false,
      blockActive: 0,
      flagModalRight: 0,
      modalTitle: 'Experiment',
      confirm: {
        txt: 'Подтвердите действие удаления категорий. После подтверждения, категория перестанет существовать, а все товары привязанные к категории будут существовать как отдельные единицы, с настройками существовавшими до того ',
        btns: [
          {
            tp: 'action',
            action: '/scm/',
            param: {},
            txt: 'Подтвердить',
          }, {
            tp: 'close',
            txt: 'Отменить',
          },
        ],
      },
      flag: 0,
      chk: false,
      select: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loader = true;
      axios.post(this.action, {
        stock: this.stock,
        mode: this.mode.val,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list.list;
            this.loader = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    changeShow() {
      if (this.outterShowing === 0) {
        this.outterShowing = 1;
      } else {
        this.outterShowing = 0;
      }

      this.$emit('changeShow', this.outterShowing);
    },
    takeVal(ind) {
      if (ind < 0) return;
      this.checkedIndex = ind;

      this.level = this.list[ind].level;
      // this.chk = this.list[ind].chk;

      const ids = [];
      this.flag = 0;
      let BreakException;
      try {
        this.list.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            ids.push(this.list[i].val);
          }

          if (ind === i) {
            ids.push(this.list[i].val);
            this.flag = 1;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      this.cats = ids;
      this.$emit('transferCats', this.cats);
    },
    changeBlockActive() {
      let i = 0;
      this.list.forEach((el) => {
        if (el.chk === 1 || el.chk === '1') {
          i += 1;
        }
      });
      if (i > 0) {
        // this.$emit('activateBlock3', 1);
        this.blockActive = 1;
      } else {
        // this.$emit('activateBlock3', 0);
        this.blockActive = 0;
      }
    },
    toggleChild(ind) {
      if (this.list[ind].child === false) {
        return;
      }
      this.level = this.list[ind].level;
      this.flag = 0;
      this.open = this.list[ind].open;
      let BreakException;
      try {
        this.list.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level === item.level) {
              throw BreakException;
            }
            // изменить show
            if (this.open === true) {
              if (this.list[i].level > this.level) {
                this.list[i].show = false;
                this.list[i].open = false;
              }
            } else if ((this.level + 1) === item.level) {
              this.list[i].show = true;
            }
          }

          if (ind === i) {
            this.flag = 1;
            // изменить open
            this.list[i].open = !this.list[i].open;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
  },
};
</script>
<style scoped>
.fa-cog{
  color: #6e6e6e;
  cursor: pointer;
}
.fa-cog.active {
  color: #41a8e7;
}
.el {
  white-space:nowrap;
  display: flex;
}
.block-link {
  display: inline-block;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border: 1px solid #b9c0cf;
  scale: 0;
  transition: 0.3s;
}
.block-link.active {
  scale: 1;
}
.block-link a {
  color: #000;
}
.empty-squad{width: 12.5px; height:16px; display: inline-block; margin-right: 5px;}
.squad-level{
  width: 10px;
  height: 20px;
  border-left: 1px solid #000;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  position:relative;
  float:left;
  left:-1px;
}
.squad-level:before{
  content: '';
  display: block;
  width: 100%;
  border-top: 1px solid #000;
  position: absolute;
  top: 36%;
  left: 0px;
}
.ierarh-border{
  border-left: 1px solid #000;
}
.item{
  display: inline-block;
  cursor: pointer;
}
.item .fa-folder, .item .fa-folder-open{
  margin-right: 3px;
  float:left;
}
.item .fa-folder{ color: green; }
.item .fa-folder-open{ color: #b91717; }
.item .empty-squad .fa-folder{ color: gray; }
.squad{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #999;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  margin-right: 4px;
  float: left;
}
.squad + span {
  display: block;
  margin-left: 24px;
  line-height: 22px;
}
.level-1{margin-left: 5px}
.level-2{margin-left: 25px}
.level-3{margin-left: 45px}
.level-4{margin-left: 65px}
.level-5{margin-left: 85px}
.level-6{margin-left: 105px}
.level-7{margin-left: 125px}
.level-8{margin-left: 145px}
.level-9{margin-left: 165px}
.level-10{margin-left: 185px}

.level-1 .squad + span,
.level-2 .squad + span,
.level-3 .squad + span,
.level-4 .squad + span,
.level-5 .squad + span,
.level-6 .squad + span,
.level-7 .squad + span,
.level-8 .squad + span,
.level-9 .squad + span,
.level-10 .squad + span{margin-left: 39px;}
</style>
