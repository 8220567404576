<template>
  <div class="form">
    <div class="form-header">
      <div class="form-title" v-if="title">{{title}}</div>
      <slot name="header"></slot>
    </div>
    <div class="form-body">
      <div v-for="(field, index) in curr_fields" :key="index">
        <template v-if="field.show
        || (!field.show && field.depend
        && field.depend > -1 && curr_fields[field.depend].value.depend == index)">
          <div v-if="field.type == 'I'" class="form-field">
            <input-text v-model="field.value"
              :def="field.placeholder"
              :lbl="field.lbl"
              :err="field.error"
              :req="field.required"
              :ind="index"
              @outError="outError" />
          </div>
          <div v-if="field.type == 'P'" class="form-field">
            <input-pass v-model="field.value"
              :def="field.placeholder"
              :lbl="field.lbl"
              :err="field.error"
              :req="field.required"
              :ind="index"
              v-on:keyup.enter="submitForm"
              @outError="outError" />
          </div>
          <div v-if="field.type == 'C'" class="form-field">
            <Checkbox v-model="field.value"
              :text="field.placeholder"
              :err="field.error" />
          </div>
          <div v-if="field.type == 'L'" class="form-field">
            <SelectCustom v-model="field.value"
              class="w100"
              :def="field.placeholder"
              :lbl="field.lbl"
              :options="field.options"
              :err="field.error"
              :req="field.required"
              :ind="index"
              @outError="outError" />
          </div>
          <div v-if="field.type == 'LM2'" class="form-field">
            <MultiSelect2 v-model="field.value"
              :def="field.placeholder"
              :lbl="field.lbl"
              :options="field.options"
              :req="field.required"
              :err="field.error" />
          </div>
          <div v-if="field.type == 'T'" class="form-field">
            <TextareaCustom v-model="field.value"
              :def="field.placeholder"
              :lbl="field.lbl"
              :err="field.error" />
          </div>
          <div v-if="field.type == 'D'" class="form-field">
            <InputCalendar v-model="field.value"
              :lbl="field.lbl"
              :type="field.align"
              :weekDays="field.weekDays"
              :months="field.months"
            />
          </div>
          <div v-if="field.type == 'R'" class="form-field">
            <Radio v-model="field.value"
              :lbl="field.lbl"
              :options="field.options"
            />
          </div>
          <div v-if="field.type == 'MLL'" class="form-field r">
            <div class="c-6">
              <MultiSelectIerarch v-model="field.value"
                :lbl="field.lbl"
                :def="field.def"
                :options="field.options" />
            </div>
            <!--
            <div class="c-6">
              <MultiSelectIerarch v-model="field.value"
                :lbl="field.lbl2"
                :def="field.def"
                :options="field.options2" />
            </div>
            -->
          </div>
          <div v-if="field.type == 'F'" class="form-field">
            <SingleFile v-model="field.value"
              :lbl="field.lbl"
              :sample="field.sample"
              :ind="index"
              :err="field.error"
              @outError="outError"
              />
          </div>
          <div v-if="field.type == 'MF'" class="form-field" >
            <MultiFields v-model="field.value"
              :lbl="field.lbl"
              :heads="field.heads"
              :fields="field.value"
              :tpl="field.tpl"
              />
          </div>
          <div v-if="field.type == 'TM'" class="form-field">
            <HourMinute
              v-model:hour="field.value"
              v-model:minute="field.value2"
              :fields="field" />
          </div>
          <div v-if="field.type == 'LD'" class="form-field">
            <SelectLoadSubSelect v-model="field.value"
              class="w100"
              :def="field.placeholder"
              :subload="field.subload"
              :fields="field.fields"
              :lbl="field.lbl"
              :options="field.options"
              :err="field.error"
              :req="field.required"
              :ind="index"
              @change="changeLd"
              @outError="outError" />

              <div class="sub-fields" v-if="field.fields.length > 0">
                <div v-for="(field2, ind2) in field.fields" :key="ind2">
                  <div v-if="field2.type == 'I'" class="form-field">
                    <input-text v-model="field2.value"
                      :def="field2.placeholder"
                      :lbl="field2.lbl"
                      :err="field2.error"
                      :req="field2.required"
                      :ind="index"
                      @outError="outError" />
                  </div>
                  <div v-if="field2.type == 'P'" class="form-field">
                    <input-pass v-model="field.value"
                      :def="field2.placeholder"
                      :lbl="field2.lbl"
                      :err="field2.error"
                      :req="field2.required"
                      :ind="index"
                      v-on:keyup.enter="submitForm"
                      @outError="outError" />
                  </div>
                  <div v-if="field2.type == 'C'" class="form-field">
                    <Checkbox v-model="field2.value"
                      :text="field2.placeholder"
                      :err="field2.error" />
                  </div>
                  <div v-if="field2.type == 'L'" class="form-field">
                    <SelectCustom v-model="field2.value"
                      class="w100"
                      :def="field2.placeholder"
                      :lbl="field2.lbl"
                      :options="field2.options"
                      :err="field2.error"
                      :req="field2.required"
                      :ind="index"
                      @outError="outError" />
                  </div>
                </div>
              </div>
          </div>
        </template>
      </div>
      <div class="wrp-spiners" style="background:#ddd" v-if="spiner">
        <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <span class="spiner--text">Отправка...</span>
      </div>
      <template v-else>
        <a v-if="btn" @click="submitForm"
          @keydown.enter="submitForm" class="wb-btn wb-btn-success">{{btn}}</a>
      </template>
    </div>
    <div class="form-footer">
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import SelectCustom from '@/components/atoms/Select.vue';
import SelectLoadSubSelect from '@/components/atoms/SelectLoadSubSelect.vue';
import HourMinute from '@/components/atoms/HourMinute.vue';
import MultiSelect2 from '@/components/atoms/MultiSelect2.vue';
import InputText from '@/components/atoms/InputText.vue';
import InputPass from '@/components/atoms/InputPass.vue';
import InputCalendar from '@/components/atoms/InputCalendar.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import Radio from '@/components/atoms/Radio.vue';
import TextareaCustom from '@/components/atoms/Textarea.vue';
import MultiSelectIerarch from '@/components/atoms/MultiSelectIerarch.vue';
import SingleFile from '@/components/atoms/SingleFile.vue';
import MultiFields from '@/components/molecules/MultiFields.vue';

export default {
  name: 'FormCustom',
  props: ['title', 'btn', 'fields', 'action', 'method'],
  components: {
    SelectCustom,
    SelectLoadSubSelect,
    HourMinute,
    InputText,
    InputPass,
    InputCalendar,
    Checkbox,
    Radio,
    TextareaCustom,
    MultiSelect2,
    MultiSelectIerarch,
    SingleFile,
    MultiFields,
  },
  data() {
    return {
      errors: [],
      spiner: false,
    };
  },
  computed: {
    curr_fields() {
      return this.fields;
    },
  },
  methods: {
    submitForm() {
      this.spiner = true;
      if (this.validateReq()) {
        if (this.action) {
          if (this.method === 'post') {
            axios.post(this.action, { form: this.curr_fields }, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
              .then((res) => {
                this.spiner = false;
                if (res.data.success === 1) {
                  this.$emit('answerForm', res.data);
                } else {
                  this.curr_fields = res.data.pack;
                }
              })
              .catch((e) => {
                this.errors.push(e);
              });
          } else {
            console.log('запрос НЕ отправлен');
            // GET-запрос тут
            this.spiner = false;
          }
        } else {
          this.$emit('answerForm', this.curr_fields);
          this.spiner = false;
        }
      } else {
        this.spiner = false;
      }
    },
    validateReq() {
      let noError = true;

      let i = this.curr_fields.length;
      while (i) {
        i -= 1;

        if (this.curr_fields[i].required && this.curr_fields[i].value === '') {
          this.curr_fields[i].error = 'Поле не должно быть пустым';
          noError = false;
        }
      }

      return noError;
    },
    /*
    selectChg(ind) {
      console.log(this.curr_fields[ind].value.depend);
    },
    */
    outError(ind) {
      this.curr_fields[ind].error = '';
    },
    changeLd(data) {
      console.log(data);
      this.curr_fields[data.ind].fields = data.fields;
    },
  },
};
</script>

<style scoped>
  .r{
    margin-left: -5px;
    margin-right: -5px;
  }
  .c-6{
    width: 50%;
    padding-right: 5px;
    padding-left: 5px;
    min-width: 320px;
    display: inline-block;
    max-height: 320px;
    overflow-y: auto;
    vertical-align: top;
    box-sizing: border-box;
  }
  .c-4{
    width: calc(100% / 3);
    padding-right: 5px;
    padding-left: 5px;
    min-width: 320px;
    display: inline-block;
    max-height: 320px;
    vertical-align: top;
    box-sizing: border-box;
  }
  .form{
    text-align:left;
    margin:0 auto;
    padding: 30px;
    background: #efefef;
    box-shadow: 0px 1px 1px #999;
    min-width: 360px;
  }
  .big{
    width: 800px;
  }
  .form-field{
    margin-bottom: 20px;
  }
  .form-title{
    margin-top: -10px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
  }
  .wrp-spiners{
    text-align: center;
    padding: 3px;
    background: #ddd;
    height: 36px;
    line-height: 36px;
  }
  .wrp-spiners span, .wrp-spiners i{
    padding: 0px 4px;
  }
  .big .form-field {
  }
  .w100{
    width: 100%;
    box-sizing: border-box;
  }
  .sub-fields{
    padding-left: 6px;
    padding-top: 15px;
    padding-bottom: 1px;
    border-left: 4px solid #aaa;
  }
</style>
