<template>
  <h1><span>Отчет по ROI</span></h1>
  <div class="filter">
    <div class="filter-stick">{{stick}}</div>
    <div class="filter-field-left">
      <template v-if="stock.type == 'L'">
        <SelectCustom @update:modelValue="showGraph" v-model="stock.value"
          style="float:left; text-align: left"
          :lbl="stock.lbl"
          :def="stock.deg"
          :options="stock.options" />
      </template>
      <template v-if="stock.type == 'A'">
        <Autocomplette @update:modelValue="showGraph" v-model="stock.value"
          :lbl="stock.lbl"
          :text="stock.text"
          :url="stock.url"
          :def="stock.placeholder"
          :options="stock.options" />
      </template>
      <template v-if="category.type == 'LF'">
        <SelectIerarch v-model="category.value"
          style="float:left; text-align: left; margin-left: 20px;"
          :lbl="category.lbl"
          :def="category.placeholder"
          :action="category.action"
          @change="showGraph" />
      </template>
      <div v-if="checkbox1.type == 'C'" class="wrp-checkbox">
        <Checkbox v-model="checkbox1.value"
          :text="checkbox1.placeholder"
          :err="checkbox1.error"
          @update:modelValue="showGraph" />
      </div>
    </div>
  </div>

  <canvas
    v-show="dataGraph.length"
    id="canvas"
    height='320'
    width='1000'
    @mousemove="placeCoordinates"
    @click="linkProd"
    @keydown.f7="linkProd"
    ></canvas>
  <div v-if="!dataGraph.length" class="text-warn-1">Нет данных по выбранным параметрам фильтра</div>
</template>

<script>
import axios from 'axios';
import SelectCustom from '@/components/atoms/Select.vue';
import Autocomplette from '@/components/atoms/Autocomplette.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import SelectIerarch from '@/components/atoms/SelectIerarch.vue';

export default {
  name: 'RRoi',
  components: {
    SelectCustom, Autocomplette, Checkbox, SelectIerarch,
  },
  data() {
    return {
      urlAction: '/scm/api/report/roi',

      stick: 'X',
      stock: {},
      checkbox1: {},
      category: {},
      dateFrom: 0,
      dateTo: 0,
      months: [],
      weekDays: [],

      dataGraph: [],
      maxVal: 0,
      maxValRound: 0,
      limitRoi: 0,
      limitRoiCoord: {
        xLeftTop: 0,
        yLeftTop: 0,
        xRightBot: 0,
        yRightBot: 0,
      },
      safeRoi: 0,
      safeRoiCoord: {
        xLeftTop: 0,
        yLeftTop: 0,
        xRightBot: 0,
        yRightBot: 0,
      },

      canvas: null,
      xMouse: 0,
      yMouse: 0,
      wCanvas: 0,
      hCanvas: 0,
      rs: [],

      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 70,
      paddingRight: 0,
      horizontalLine: 5,

      errors: [],
    };
  },
  mounted() {
    const c = document.getElementById('canvas');
    this.canvas = c.getContext('2d');

    c.width = window.innerWidth - 12 * 2;

    window.addEventListener('resize', () => {
      c.width = window.innerWidth - 12 * 2;
      this.wCanvas = c.width;

      this.drawStuff();
    }, false);

    this.wCanvas = c.width;
    this.hCanvas = c.height;

    if (localStorage.getItem('stock')) {
      this.urlAction += `?stockID=${localStorage.getItem('stock')}`;
    }
    this.init();
  },
  methods: {
    init() {
      axios.get(this.urlAction)
        .then((res) => {
          this.dataGraph = res.data.list;
          this.maxVal = res.data.maxVal;
          this.stock = res.data.stock;
          this.checkbox1 = res.data.checkbox;
          this.category = res.data.category;
          this.limitRoi = res.data.limitRoi;
          this.safeRoi = res.data.safeRoi;
          this.stick = res.data.stick;

          this.drawStuff();
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    showGraph() {
      setTimeout(() => {
        if (this.stock.value.val) {
          localStorage.setItem('stock', this.stock.value.val);
        } else {
          localStorage.setItem('stock', this.stock.value);
        }

        if (this.stock.value.val) {
          this.urlAction = `/scm/api/report/roi?stockID=${this.stock.value.val}`;
        } else if (this.stock.value) {
          this.urlAction = `/scm/api/report/roi?stockID=${this.stock.value}`;
        }

        if (this.category.value.val) {
          this.urlAction = `${this.urlAction}&categoryID=${this.category.value.val}`;
        }

        if (this.checkbox1.value) {
          this.urlAction = `${this.urlAction}&nch=${this.checkbox1.value}`;
        }

        axios.get(this.urlAction)
          .then((res) => {
            this.dataGraph = res.data.list;
            this.maxVal = res.data.maxVal;
            this.limitRoi = res.data.limitRoi;
            this.safeRoi = res.data.safeRoi;
            this.stick = res.data.stick;

            this.drawStuff();
          })
          .catch(() => {
            this.$router.push('/404');
          });
      }, 50);
    },
    drawStuff() {
      this.rs = [];
      const ctx = this.canvas;

      let len = this.dataGraph.length;

      const heightGraph = this.hCanvas - this.paddingTop - this.paddingBottom;
      const widthGraph = this.wCanvas - this.paddingLeft - this.paddingRight;

      // const widthColumn = 30;
      ctx.lineWidth = 0.5;
      ctx.fillStyle = '#fff';

      ctx.fillRect(0, 0, this.wCanvas, this.hCanvas);

      this.maxValRound = this.roundRazrad(this.maxVal);

      this.drawSetka();

      const vHorizontal = widthGraph / len;
      const offsetRight = (vHorizontal / 100) * 10;

      ctx.fillStyle = '#82b5ef';
      let x;
      let yRect;
      let xRect;
      while (len > 0) {
        len -= 1;

        x = (heightGraph * this.dataGraph[len].val) / this.maxValRound;
        yRect = heightGraph - x + this.paddingTop;

        xRect = vHorizontal * len + this.paddingLeft;
        ctx.fillRect(xRect, yRect, vHorizontal - offsetRight, x);

        this.rs.push([
          xRect,
          yRect,
          vHorizontal - offsetRight,
          x,
          this.dataGraph[len].name,
          this.dataGraph[len].val,
          this.dataGraph[len].url,
        ]);

        if (this.limitRoi > this.dataGraph[len].val) {
          this.limitRoiCoord = {
            xLeftTop: xRect - (offsetRight / 2),
            yLeftTop: 0,
            xRightBot: xRect - (offsetRight / 2),
            yRightBot: this.hCanvas,
          };
        }
        if (this.safeRoi > this.dataGraph[len].val) {
          this.safeRoiCoord = {
            xLeftTop: xRect - (offsetRight / 2),
            yLeftTop: 0,
            xRightBot: xRect - (offsetRight / 2),
            yRightBot: this.hCanvas,
          };
        }
      }
      if (this.limitRoiCoord.xLeftTop !== 0) {
        this.drawRoiLimit();
      }
      if (this.safeRoiCoord.xLeftTop !== 0) {
        this.drawRoiSafe();
      }
    },
    drawRoiLimit() {
      const ctx = this.canvas;
      ctx.strokeStyle = '#e45c57';
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.moveTo(this.limitRoiCoord.xLeftTop, this.limitRoiCoord.yLeftTop);
      ctx.lineTo(this.limitRoiCoord.xRightBot, this.limitRoiCoord.yRightBot);
      ctx.closePath();
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#ccc';

      ctx.textAlign = 'left';
      ctx.fillStyle = '#e45c57';
      ctx.fillText(this.limitRoi, this.limitRoiCoord.xLeftTop + 4, 14);
      ctx.textAlign = 'right';
      ctx.fillStyle = '#fff';
    },
    drawRoiSafe() {
      const ctx = this.canvas;
      ctx.strokeStyle = '#228B22';
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.moveTo(this.safeRoiCoord.xLeftTop, this.safeRoiCoord.yLeftTop);
      ctx.lineTo(this.safeRoiCoord.xRightBot, this.safeRoiCoord.yRightBot);
      ctx.closePath();
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#ccc';

      ctx.textAlign = 'left';
      ctx.fillStyle = '#228B22';
      ctx.fillText(this.safeRoi, this.safeRoiCoord.xLeftTop + 4, 14);
      ctx.textAlign = 'right';
      ctx.fillStyle = '#fff';
    },
    drawSetka() {
      const ctx = this.canvas;
      ctx.strokeStyle = '#ccc';
      ctx.font = 'normal normal 16px Arial';
      const heightGraph = this.hCanvas - this.paddingTop - this.paddingBottom;
      const vInterval = heightGraph / this.horizontalLine;
      const vIntervalReal = this.maxValRound / this.horizontalLine;

      for (let i = this.horizontalLine; i >= 0; i -= 1) {
        const xPos = vInterval * i + this.paddingTop;

        ctx.beginPath();
        ctx.moveTo(this.paddingLeft, xPos);
        ctx.lineTo(this.wCanvas - this.paddingRight, xPos);
        ctx.closePath();
        ctx.stroke();

        ctx.textAlign = 'right';
        ctx.fillStyle = '#000';
        ctx.fillText(this.maxValRound - vIntervalReal * i, 50, vInterval * i + 6 + this.paddingTop);
        ctx.fillStyle = '#fff';
      }
    },
    placeCoordinates(e) {
      const ctx = this.canvas;
      // this.canvas.clearRect(0, 0, this.width, this.height);
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, this.wCanvas, this.hCanvas);

      this.xMouse = e.offsetX;
      this.yMouse = e.offsetY;

      this.drawSetka();

      this.drawRoiLimit();
      this.drawRoiSafe();

      let len = this.rs.length;
      while (len > 0) {
        len -= 1;

        if (this.rectPointCollision([e.offsetX, e.offsetY], this.rs[len])) {
          this.drawRect(this.rs[len], '#e45c57');
          this.drawText(this.rs[len]);
        } else {
          this.drawRect(this.rs[len], '#82b5ef');
        }
      }
    },
    drawRect(r, color) {
      const ctx = this.canvas;

      ctx.fillStyle = color;
      ctx.fillRect(...r.slice(0, 4));
    },
    rectPointCollision(point, rect) {
      return (
        point[0] > rect[0]
        && point[0] < rect[0] + rect[2]
        && point[1] > rect[1]
        && point[1] < rect[1] + rect[3]
      );
    },
    drawText(r) {
      const ctx = this.canvas;

      // const w = ctx.measureText(r[4]).width;

      ctx.fillStyle = '#fff';
      const wInfo = 320;
      const hInfo = 150;
      ctx.fillRect(this.wCanvas - wInfo, 0, wInfo, hInfo);
      ctx.strokeStyle = '#000000';
      ctx.strokeRect(this.wCanvas - wInfo, 0, wInfo, hInfo);
      ctx.fillStyle = '#333';
      ctx.textAlign = 'left';
      ctx.font = 'normal normal 16px Arial';

      const str1 = 0;
      const str2 = str1 + String(r[5]).length;
      let posY = 25;
      if (str2 > 34) {
        ctx.fillText('ROI:', this.wCanvas - wInfo + 10, posY);
        posY += 20;
        ctx.fillText(`${r[5]}`, this.wCanvas - wInfo + 10, posY);
        posY += 20;
      } else {
        ctx.fillText(`ROI: ${r[5]}`, this.wCanvas - wInfo + 10, 25);
        posY += 20;
      }

      ctx.font = 'bold normal 16px Arial';
      const str3 = String(r[4]).length;
      if (str3 > 34) {
        const arrSplitStr = r[4].split(' ');

        let strTemp = '';
        while (arrSplitStr.length) {
          const lenTemp = strTemp.length + arrSplitStr[0].length;

          if (lenTemp > 34) {
            ctx.fillText(strTemp, this.wCanvas - wInfo + 10, posY);
            posY += 20;
            strTemp = '';
          }

          strTemp = `${strTemp}${arrSplitStr[0]} `;
          arrSplitStr.shift();
        }

        ctx.fillText(strTemp, this.wCanvas - wInfo + 10, posY);
        posY += 20;
      } else {
        ctx.fillText(`${r[4]}`, this.wCanvas - wInfo + 10, posY);
        posY += 20;
      }
    },
    roundRazrad(x) {
      let p = 10;
      let cntRazrad = 19;
      for (let i = 1; i < 19; i += 1) {
        if (x < p) {
          cntRazrad = i;
          break;
        }
        p *= 10;
      }

      let decimalRange = 1;
      for (let i = 0; i < cntRazrad - 1; i += 1) {
        decimalRange *= 10;
      }
      return (parseInt(x / decimalRange, 10) + 1) * decimalRange;
    },
    linkProd(e) {
      let len = this.rs.length;
      while (len > 0) {
        len -= 1;

        if (this.rectPointCollision([e.offsetX, e.offsetY], this.rs[len])) {
          this.$router.push(this.rs[len][6]);
        }
      }
    },
  },
};
</script>

<style scoped>
.wrp-checkbox{
  float: left;
  margin-left: 20px;
}
.panel-bottom {
  display:flex;
  position: fixed;
  bottom:0px;
  left:0px;
  width: 100%;
  background: #cacaca;
  padding-right:12px;
  text-align: right
}
.panel-bottom .block-info {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 5px;
}

.filter {
  position: relative;
  margin-bottom: 30px;
  background: #efefef;
  padding: 12px 12px 12px 70px;
  box-shadow: 0px 0px 1px inset #999;
  margin-top: 16px;
  min-height: 66px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.filter .filter-stick {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 25px;
  text-align: center;
}

.area-content{
  padding-left: 12px;
  padding-right: 12px;
}
.filter-field-left {
  margin-right: 20px;
  float:left;
  text-align: left;
}
</style>
