<template>
  <div class="main-block">
    <SelectCustom
      v-model="selectedOperation"
      :options="field"
      :def="defLbl"
      @change="clearError"
    />
    {{ error }}
    <div v-if="selectedOperation !== false">
      <pre>{{ selectedOperation }}</pre>

      <div v-for="(field, ind) in selectedOperation.fields" :key="ind">
        <div v-if="field.type == 'L'" class="form-field">
          <SelectCustom v-model="field.value"
            class="w100"
            :def="field.placeholder"
            :lbl="field.lbl"
            :options="field.options"
            :err="field.error"
            :req="field.required"
            :ind="index"
            @change="clearError"
            @outError="outError" />
        </div>

        <div v-if="field.type == 'I'" class="form-field">
          <input-text v-model="field.value"
            @keypress="validateInput"
            :def="field.placeholder"
            :lbl="field.lbl"
            :err="field.error"
            :req="field.required"
            :ind="index"
            @change="clearError"
            @outError="outError" />
        </div>

        <template v-if="field.type == 'Z'">
          <SelectCustom v-model="field.value"
            class="w100"
            :def="field.placeholder"
            :lbl="field.lbl"
            :options="field.options"
            :err="field.error"
            @change="clearError"
            />

          <template v-if="field.value.fields">
            <div v-for="(field2, ind2) in field.value.fields" :key="ind2">
              <template v-if="field2.type == 'I'">
                <input-text v-model="field2.value"
                  @keypress="validateInput"
                  :def="field2.placeholder"
                  :lbl="field2.lbl"
                  :err="field2.error"
                  :req="field2.required" />
              </template>
            </div>
          </template>
        </template>
        <div v-if="selectedOperation.operation === 'link_supp_prod'" class="el--form">
          <div v-for="(el, ind) in field.options" :key="ind"
            :class="{'c-2': el.type == 'L', 'c-5': el.type == 'I'}" >
            <template v-if="el.type == 'L'">
              <SelectCustom v-model="el.value"
                :def="el.placeholder"
                :lbl="el.lbl"
                :options="el.options"
                :err="el.error"
                @change="clearError" />
            </template>
            <template v-if="el.type == 'I'">
              <input-text v-model="el.value"
                @keypress="validateInput($event, ind)"
                :def="el.placeholder"
                :lbl="el.lbl"
                :err="el.error"
                :req="el.required" />
            </template>
          </div>
          <div class="c-8">
            <i @click="addPack()" class="fa fa-plus" aria-hidden="true"></i>
          </div>
          <div class="pack-common">
            <div v-for="(el, ind) in this.packCommon" :key="ind"
              class="pack-group">
              <i @click="removePack(ind)"
                class="fa fa-times" aria-hidden="true"></i>
              <div class="pack-item c-2">{{el[0].value.lbl}}</div>
              <div class="pack-item c-5">{{el[1].value}}</div>
              <div class="pack-item c-5">{{el[2].value}}</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedOperation.operation === 'supp_all_unlink'">
        {{ selectedOperation.lbl }}
      </div>

      <!-- Кнопка -->
      <template v-if="selectedOperation.btn">
        <div v-if="selectedOperation.btn.show" class="wb-btn-sh wb-btn-success"
          @click="runOperation()"
          @keydown.f7="runOperation()" >
          {{selectedOperation.btn.lbl}}
        </div>
      </template>

      <!-- Ответ -->
      <div v-if="answer.length > 0">
        {{answer}}
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import SelectCustom from '@/components/v2/serj/Select.vue';
import InputText from '@/components/atoms/InputText.vue';

export default {
  name: 'OperationsManage',
  props: {
    action: {
      type: String,
      default: '',
    },
    stock: {
      type: Number,
      default: 0,
    },
    field: {
      type: Array,
    },
    prods: {
      type: Array,
    },
    cats: {
      type: Array,
    },
  },
  components: {
    SelectCustom,
    InputText,
  },
  data() {
    return {
      success: false,
      answer: '',
      packCommon: [],
      selectedOperation: false,
      defLbl: 'Выберите операцию',
      currentValOperation: 0,
      actionForm: '/v2/product/multi-set',
      request: 'post',
      specialOperaions: [
        'type_chg_cu',
        'link_supp_prod',
        'supp_all_unlink',
      ],
      error: '',
      errors: [],
    };
  },
  methods: {
    validateInput(event, ind) {
      const field = this.selectedOperation.fields[0];
      let typeChgCu = false;
      let linkSuppProd = false;
      let valid = '';

      if (this.selectedOperation.operation === 'type_chg_cu') {
        field.value.fields[0].error = '';
        typeChgCu = true;
        valid = field.value.fields[0].valid;
      } else if (this.selectedOperation.operation === 'link_supp_prod') {
        field.options[ind].error = '';
        linkSuppProd = true;
        valid = field.options[ind].valid;
      } else {
        field.error = '';
        valid = field.valid;
      }

      if ((event.charCode > 57 || event.charCode < 48) && valid === 'number') {
        if (typeChgCu) {
          field.value.fields[0].error = 'Вводить можно только числа!';
        } else if (linkSuppProd) {
          field.options[ind].error = 'Вводить можно только числа!';
        } else {
          field.error = 'Вводить можно только числа!';
        }

        event.preventDefault();
      }
    },
    validate() {
      this.answer = '';
      const field = this.selectedOperation.fields[0];
      let valid = '';
      let value = '';

      if (this.selectedOperation.operation === 'type_chg_cu') {
        value = field.value.fields[0].value.trim();
        valid = field.value.fields[0].valid;
        field.value.fields[0].error = '';
      } else if (this.selectedOperation.operation === 'link_supp_prod') {
        value = [];
        valid = [];

        field.value.forEach((valueOne) => {
          valueOne.forEach((el) => {
            if (el.type === 'I') {
              value.push(el.value.trim());
              valid.push(el.valid);
            }
          });
        });

        field.options.forEach((el) => {
          el.error = '';
        });
      } else {
        value = field.value.trim();
        valid = field.valid;
        field.error = '';
      }

      if (value === '') {
        this.error = 'пустая строка';
        return false;
      }

      if (valid === 'number' && !/^\d+$/.test(value)) {
        this.error = 'Вводить можно только числа!';
        return false;
      }

      if (Array.isArray(value)) {
        for (let index = 0; index < value.length; index += 1) {
          const valueOne = value[index];
          const validOne = valid[index];

          if (valueOne === '') {
            this.error = 'пустые строки';
            return false;
          }

          if (validOne === 'number' && !/^\d+$/.test(valueOne)) {
            console.log('Отработало ');
            this.error = 'Вводить можно только числа!';
            return false;
          }
        }
      }

      this.error = '';
      return true;
    },
    runOperation() {
      if (!this.validate()) {
        return;
      }
      this.answer = '';

      axios.post(this.actionForm, {
        stock: this.stock,
        cats: this.cats,
        prods: this.prods,
        field: this.selectedOperation,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer = res.data.answer;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    clearError() {
      this.error = '';
    },
    addPack() {
      const el = JSON.parse(JSON.stringify(this.selectedOperation.fields[0].options));
      this.packCommon.push(el);

      if (this.packCommon.length) {
        this.selectedOperation.btn.show = true;
      } else {
        this.selectedOperation.btn.show = false;
      }

      this.selectedOperation.fields[0].value = this.packCommon;
    },
    removePack(ind) {
      this.packCommon.splice(ind, 1);

      if (this.packCommon.length) {
        this.selectedOperation.btn.show = true;
      } else {
        this.selectedOperation.btn.show = false;
      }

      this.selectedOperation.fields[0].value = this.packCommon;
    },
  },
};
</script>
