<template>
  <div class="wrp-modal">
    <div class="modal-overlay" @click="close"
      @keydown.f7="close">
    </div>
    <div class="modal-right">
      <div class="modal-close" @click="close" @keydown.f7="close">
        <i class="fa fa-times"></i>
      </div>
      <div v-if="title" class="modal-right--head">
        <div class="modal-title">{{title}}</div>
      </div>
      <div class="modal-right--body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalRightС',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      run: false,
    };
  },
  mounted() {
    // document.body.style.overflowY = 'hidden';
  },
  methods: {
    close() {
      document.body.style.overflow = 'visible';
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.wrp-modal{
  position: relative;
  z-index: 21;
}
.modal-overlay{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 50;
  background: #383838b5;
  cursor: pointer;
}
.modal-right{
  position: fixed;
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: #f6f8fa;
  animation: burgerAnimation 0.4s;
  border-left: 5px solid #6dc2ed;
  text-align:left;
  cursor: auto;
}
.w100 .modal-right{
  width: 90%;
}
.modal-close {
  font-size: 22px;
  position: absolute;
  top: 17px;
  left: -51px;
  cursor: pointer;
  background-color: #6dc2ed;
  color: #fff;
  padding: 10px 14px 10px 15px;
}
.modal-right--head{
  padding: 25px;
  background-color: #aad2e6;
}

.modal-title{
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
}

.modal-right--body{
  overflow-y: auto;
  height: calc(100vh);
}
.body-padding{
  padding: 0px 30px 30px;
}

.modal-right--head + .modal-right--body{
  height: calc(100vh - 84px);
}

</style>
