<template>
  <div class="form">
    <InputFile v-model="file" :accept="accept"
      @change="handleFileUpload"
      class="block-squade" v-if="!selectedFile" />
    <div class="block-squade flex" v-else-if="textAnswer.length == ''">
      <div>{{file.name}}
        <button @click="unselectFile">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <button @click="send" class="btn-info">Загрузить нормо-часы</button>
    </div>
    <div v-else :class="{'alert-info': flagAnswer == 1, 'alert-danger': flagAnswer == 0}">
      {{textAnswer}}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import InputFile from '@/components/v2/cross/input/InputFile.vue';

export default {
  name: 'NormTime',
  components: {
    InputFile,
  },
  data() {
    return {
      run: false,
      selectedFile: false,
      file: {},
      accept: '.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      textAnswer: '',
      flagAnswer: 0,
    };
  },
  computed() {

  },
  methods: {
    handleFileUpload() {
      this.selectedFile = true;
    },
    unselectFile() {
      this.selectedFile = false;
    },
    send() {
      const formData = new FormData();
      formData.append('file', this.file);
      axios.post(
        '/god/norm-time/save',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ).then((res) => {
        this.textAnswer = res.data.answer;
        this.flagAnswer = res.data.success;
      }).catch(() => {
        console.log('FAILURE!!');
      });
    },
  },
};
</script>

<style scoped>
.form{
  width: 220px;
  background: #cefada;
  padding: 24px;
  cursor: pointer;
}
.offset-r{
  margin-right: 6px;
}
tr.first{cursor: pointer;}
tr.first:hover td{opacity: 0.9}
.instrumental{position: fixed;
  left:0px;
  bottom:0px;
  background: #aaa;
  width: 100%;
}

.btn.btn-add{
  background: #5981ce;
  color: #fff;
  padding: 6px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.btn.btn-add:hover{
  background: #3b70d7;
  padding: 8px;
}
.btn.btn-add span, .btn.btn-add i{
  vertical-align: middle;
}

.list-table td.cell-act{
  padding: 0px;
}
.acts__item{
  padding:0px
}
.acts__item a{
  font-size: 25px;
}
.list-table{
  width:100%;
  text-align: left;
}

.list-table tr.odd{
  background: #e5f0ff;
}

.list-table th{
  background: #5981ce /* #5390ef */;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  line-height: 18px;
}
.list-table td{
  vertical-align:top;
  padding: 8px;
}
.list-list-item{
  padding: 4px;
  background: #ccc;
  margin-bottom: 1px;
  border-radius:2px;
  font-size:12px;
}
.list-list-item .unlink{float:right; padding-left:4px;}
.stick-right{
  float: right;
  height: 34px;
  line-height: 34px;
  width: 34px;
  text-align: center;
  background: #f9d0d0;
  border: 1px solid #a17979;
  margin-top: 1px;
  color: #700e0e;
}
</style>
