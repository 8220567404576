<template>
  <div class="wb-select" :class="{'error-input': err, 'req': req}">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <SelectDef v-model="selected"
      :options = "options"
      :def = "def"
      :err = "err"
      :req = "true"
      @update:modelValue="selectOption"></SelectDef>
    <transition name="fade">
      <div v-if="showExt" style="margin-top: 20px;">
        <div v-for="(field, ind2) in extfields" :key="ind2" class="form-field">
          <component :is="field.component" v-bind="field.conf"
            v-model="field.value" @update:modelValue="chgVal"></component>
        </div>
      </div>
    </transition>
    <transition>
      <span class="error" v-if="err">
        <i class="close" @click="onClose"
          @keydown.f7="onClose">&#10006;</i>
        {{err}}
      </span>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import SelectDef from '@/components/v2/cross/form/fields/SelectDef.vue';
import InputText from '@/components/v2/cross/form/fields/InputText.vue';

export default {
  name: 'SelectFields',
  components: {
    SelectDef,
    InputText,
  },
  props: {
    lbl: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [
        {
          val: 0,
          lbl: 'Пустое поле',
        },
      ],
    },
    def: {
      type: String,
      default: '',
    },
    err: {
      type: String,
      default: '',
    },
    req: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
    ind: {
      type: Number,
      default: 0,
    },
    subload: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showExt: false,
      extfields: [],
      selected: 0,
    };
  },
  methods: {
    selectOption() {
      axios.get(this.subload, { params: { val: this.selected.val } })
        .then((res) => {
          this.extfields = res.data;
          this.showExt = true;
          // this.$emit('change', { ind: this.ind, fields: res.data });

          // this.$emit('update:modelValue', option);
        });
    },
    closeSelect() {
      this.showList = false;
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
    chgVal() {
      this.saveFields = {};
      // let noError = true;
      let i = this.extfields.length;
      while (i) {
        i -= 1;
        const el = this.extfields[i];
        if (el.conf.req && el.value === '') {
          this.extfields[i].conf.err = 'Поле не должно быть пустым';
          // noError = false;
        }
        this.saveFields[el.conf.name] = el.value;
      }

      this.$emit('update:modelValue', {
        sel: this.selected.val,
        fields: this.saveFields,
      });
    },
  },
};
</script>

<style scoped>
.label{
  color: #909090;
  top: 0px; left: 0px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  transition: top 0.3s, left 0.3s, font-size 0.3s, color 0.3s;
}
.label.active{
  color: #345F90;
}
.form-field{
  margin-bottom: 20px;
}
</style>
